import { CssBaseline } from "@mui/material";
import { red, grey} from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

export const theme = createTheme({
  typography: {
      fontFamily: ["Lato", "Ubuntu", "Roboto", "sans-serif"].join(","),
      subtitle1: {
          fontSize: "16px",
          fontStyle: "normal",
          lineHeight: "19px",
          fontWeight: 700,
      },
      button: {
          textTransform: "capitalize",
          fontWeight: 700,
      },
  },
  palette: {
      mode: "dark",
      primary: {
          main: grey[500],
          light: grey[300],
      },
      secondary: {
          main: "#19857b",
      },
      error: {
          main: red.A400,
      },
      background: {
          default: "#2c2c2e",
          paper: "#2c2c2e",
      },
      text: {
          primary: "#D6D7DC",
      },
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
  </ThemeProvider>
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
