import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import PlacesAutocomplete from "react-places-autocomplete";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import NearMeIcon from "@mui/icons-material/NearMe";
import MenuItem from "@mui/material/MenuItem";
import CampaignIcon from "@mui/icons-material/Campaign";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import moment from "moment-timezone";
import { useFormik } from "formik";
import chatgptLogo from "./images/ChatGPT_log.png";
import { Container } from "@mui/material";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import {
    Avatar,
    Chip,
    LinearProgress,
    ListItemAvatar,
    CircularProgress,
    Divider,
} from "@mui/material";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate,
    Navigate,
    useLocation,
} from "react-router-dom";
import { useGeolocated } from "react-geolocated";
import Box from "@mui/material/Box";
import { Grid, Tooltip, Menu, Tabs, Tab, TextField } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
    ArrowBackIos,
    ArrowForwardIos,
    MoreVert,
    PlayArrow,
    VolumeOff,
    AccountCircle,
} from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import logo from "./images/logo.png";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/analytics";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { FormControlLabel, ListItem } from "@mui/material";
import { Link } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Switch from "@mui/material/Switch";
import InfoIcon from "@mui/icons-material/Info";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import mixpanel from "mixpanel-browser";
import emailjs from "emailjs-com";
import { FileCopy } from "@mui/icons-material";

firebase.initializeApp({
    apiKey: "AIzaSyDMLHGNFMgPgUDzuNPRRmdEw13Mo2NW9qE",
    authDomain: "ultimate-2c4e3.firebaseapp.com",
    databaseURL: "https://ultimate-2c4e3.firebaseio.com",
    projectId: "ultimate-2c4e3",
    storageBucket: "ultimate-2c4e3.appspot.com",
    messagingSenderId: "476760652743",
    appId: "1:476760652743:web:361fcbaf503c0f842e77e2",
    measurementId: "G-T7HS5R4D2N",
});

mixpanel.init("df6a9a59ac8d41abe2eb400ea15b5c1a");

// Set this to a unique identifier for the user performing the event.
// eg: their ID in your database or their email address.

export const uid = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

const useWindowWidth = () => {
    // State to store window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Listener event on resize
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    // Add event listener using useEffect
    useEffect(() => {
        window.addEventListener("resize", handleResize);

        // Clean up the event listener
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowWidth;
};

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();
const analytics = firebase.analytics();
let documentAdded = false;

function isEmailSentLastHour(userId) {
    const storedTimestamp = localStorage.getItem(userId);
    if (storedTimestamp) {
        const currentTime = new Date().getTime();
        const lastHour = 60 * 60 * 1000; // 1 hour in milliseconds
        const difference = currentTime - storedTimestamp;

        if (difference <= lastHour) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

const sendEmail = async (otherUserId, message) => {
    if (!isEmailSentLastHour(otherUserId)) {
        axios
            .post(
                "https://us-central1-ultimate-2c4e3.cloudfunctions.net/getUserEmail",
                {
                    userId: otherUserId,
                }
            )
            .then((response) => {
                if (response?.data?.email?.length > 0) {
                    const templateParams = {
                        user_email: response.data.email,
                        message: message,
                        reply_to: "support@uniget.com",
                    };

                    emailjs
                        .send(
                            "service_h1b8ywv",
                            "template_sd03w7l",
                            templateParams,
                            "MJ161_bBsg9s5Og8W"
                        )
                        .then(
                            (result) => {
                                const timestamp = new Date().getTime();
                                localStorage.setItem(otherUserId, timestamp);
                            },
                            (error) => {}
                        );
                }
            })
            .catch((error) => {});
    }
};

const API_URL = "https://stingray-app-iip8m.ondigitalocean.app";
const API_KEY =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpIdW5hbkNoYWluQUlMaWtlQUJvc3MiLCJpYXQiOjE1MTYyMzkwMjJ9.won_gEy1XlbvGHa1mJlDUA6x7WxePIOAxrHKcAquoEA";

const goBackOrHome = () => {
    if (document.referrer.includes(window.location.hostname)) {
        window.history.back();
    } else {
        window.location.href = "/";
    }
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ padding: 10, maxHeight: "calc(100vh - 160px)" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
}

export default function App() {
    const [user] = useAuthState(auth);
    const usersRef = firestore.collection("users");

    const [isAdmin, setIsAdmin] = React.useState(false);

    let query = usersRef.where("uid", "==", "asdfasdf");

    if (user) {
        query = usersRef.where("uid", "==", user.uid);
    }

    const [users] = useCollectionData(query, { idField: "id" });
    const [url, setUrl] = useState(window.location.href);

    useEffect(() => {
        const handleLocationChange = () => {
            setUrl(window.location.href);
        };

        window.addEventListener("popstate", handleLocationChange);

        return () => {
            window.removeEventListener("popstate", handleLocationChange);
        };
    }, []);

    useEffect(() => {
        mixpanel.track("Page View", { url });
    }, [url]);

    useEffect(() => {
        if (user && user?.uid) {
            mixpanel.identify(user?.uid);
        }
    }, [user]);

    useEffect(() => {
        if (users && users[0] && users[0].isAdmin === true) {
            setIsAdmin(true);
        }
    }, [users]);
    const isWidthGreaterThan668 = useWindowWidthGreaterThan(820);

    return (
        <Box sx={{ zIndex: 1, width: "100%", bgcolor: "background.paper" }}>
            <div>
                <Router>
                    <Routes>
                        <Route path="/" element={<Navigate to="new" />} />
                        <Route
                            path="/chats"
                            element={<Home tab="chats" isAdmin={isAdmin} />}
                        />
                        <Route
                            path="/new"
                            element={<Home tab="new" isAdmin={isAdmin} />}
                        />
                        <Route
                            path="/chats"
                            element={<Home tab="chats" isAdmin={isAdmin} />}
                        />
                        <Route
                            path="/requests"
                            element={<Home tab="requests" isAdmin={isAdmin} />}
                        />
                        <Route path="/request" element={<Request />} />
                        <Route
                            path="/profile"
                            element={<Home tab="profile" isAdmin={isAdmin} />}
                        />
                        <Route
                            path="/user"
                            element={
                                <>
                                    <Profile />
                                </>
                            }
                        />

                        <Route
                            path="/chat"
                            element={<Chat isAdmin={isAdmin} />}
                        />

                        <Route path="/chatbot" element={<BotBuilding />} />

                        <Route path="/terms" element={<Terms />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/privacy" element={<Privacy />} />
                    </Routes>
                </Router>
            </div>
            {isWidthGreaterThan668 && (
                <Button
                    href="/contact"
                    target="_blank"
                    sx={{
                        position: "fixed",
                        bottom: 0,
                        right: 0,
                        zIndex: 9999999,
                    }}
                >
                    Feedback
                </Button>
            )}
        </Box>
    );
}

function useWindowWidthGreaterThan(width) {
    const [isWidthGreaterThan, setIsWidthGreaterThan] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsWidthGreaterThan(window.innerWidth > width);
        };
        // Initial check
        handleResize();
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Clean up event listener on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [width]);

    return isWidthGreaterThan;
}
function LocationFromBrowser({ onResopons }) {
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 500000,
        });

    useEffect(() => {
        const getAddress = () => {
            axios
                .get(
                    "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                        coords.latitude +
                        "," +
                        coords.longitude +
                        "&key=AIzaSyAyPEE3jw-iGcFfUOwwgJK1ztbLbF2sHTE"
                )
                .then(function (response) {
                    // handle success
                    onResopons(response);
                })
                .catch(function (error) {})
                .finally(function () {
                    // always executed
                });
        };
        if (coords && coords.latitude && coords.longitude) {
            getAddress();
        }
    }, [coords]);

    return <></>;
}

function TopBar({ tab, isAdmin }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isAdressSelected, setIsAddressSeleceted] = useState(
        localStorage.getItem("mainAddress") ? true : false
    );
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const windowWidth = useWindowWidth();
    const [mainAddress, setMainAddress] = useState(
        localStorage.getItem("mainAddress")
            ? JSON.parse(localStorage.getItem("mainAddress"))
            : null
    );
    const handleCopyUrl = () => {
        navigator.clipboard.writeText(API_URL);
    };

    const handleCopyToken = () => {
        navigator.clipboard.writeText(API_KEY);
    };

    const open = Boolean(anchorEl);
    const handleClose = (e, option) => {
        setAnchorEl(null);
        if (option === "About") {
            window.location.href = "/about";
        }
        if (option === "Contact / Feedback") {
            window.location.href = "/contact";
        }
        if (option === "Build a Bot") {
            window.location.href = "/chatbot";
        }

        if (option === "Terms of Service") {
            window.location.href = "/terms";
        }
        if (option === "Privacy Policy") {
            window.location.href = "/privacy";
        }
        if (option === "Log Out") {
            auth.signOut();
        }
    };

    const [askLocationFromBrowser, setAskLocationFromBrowser] = useState(false);

    useEffect(() => {
        if (mainAddress) {
            localStorage.setItem("mainAddress", JSON.stringify(mainAddress));
            setIsAddressSeleceted(true);
        }
    }, [mainAddress]);

    const [openAbout, setOpenAbout] = React.useState(false);
    firebase.auth().onAuthStateChanged(function (user) {
        if (!(user || localStorage.getItem("isAboutHidden") === "true")) {
            setOpenAbout(true);
        }
    });

    useEffect(() => {
        if (openAbout) {
            localStorage.setItem("isAboutHidden", "true");
        }
    }, [openAbout]);

    const messagesRef = firestore.collection("messages");
    const [user] = useAuthState(auth);
    const [requests, setRequests] = useState([]);
    const [adminRequests, setAdminRequests] = useState([]);
    let query = messagesRef.where("uid", "==", "asdfasdf").orderBy("createdAt");
    let assistQuery = messagesRef.orderBy("createdAt").limit(1);
    if (isAdmin) {
        assistQuery = messagesRef.orderBy("createdAt");
    }
    const [adminMessages] = useCollectionData(assistQuery, { idField: "id" });

    if (user) {
        query = messagesRef.where("uid", "==", user.uid).orderBy("createdAt");
    }

    const [myMessages] = useCollectionData(query, { idField: "id" });

    useEffect(() => {
        if (
            myMessages !== undefined &&
            myMessages.length > 0 &&
            requests.length < 1
        ) {
            let ids = [];
            myMessages.forEach((message) => {
                if (message.chatId && !ids.includes(message.chatId)) {
                    let filteredArray = myMessages.filter(function (obj) {
                        return obj.chatId === message.chatId;
                    });
                    filteredArray.sort((a, b) =>
                        a.createdAt > b.createdAt ? -1 : 1
                    );

                    ids.unshift({
                        id: message.chatId,
                        firstMessage: filteredArray[0].text,
                    });
                }
            });
            setRequests(ids);
        }
    }, [myMessages]);

    useEffect(() => {
        if (
            isAdmin &&
            adminMessages !== undefined &&
            adminMessages.length > 1
        ) {
            let ids = [];
            adminMessages.forEach((message) => {
                if (message.chatId && !ids.includes(message.chatId)) {
                    let filteredArray = adminMessages.filter(function (obj) {
                        return obj.chatId === message.chatId;
                    });
                    filteredArray.sort((a, b) =>
                        a.createdAt > b.createdAt ? -1 : 1
                    );
                    if (
                        filteredArray &&
                        filteredArray.length > 0 &&
                        filteredArray[0].uid !== "login"
                    ) {
                        ids.unshift({
                            id: message.chatId,
                            firstMessage: filteredArray[0].text,
                        });
                    }
                }
            });
            setAdminRequests(ids);
        }
    }, [adminMessages, isAdmin]);

    const [value, setValue] = React.useState(
        tab === "chats" ? 1 : tab === "requests" ? 2 : tab === "profile" ? 3 : 0
    );

    useEffect(() => {
        setValue(
            tab === "other"
                ? -1
                : tab === "new"
                ? 0
                : tab === "chats"
                ? 1
                : tab === "requests"
                ? 2
                : tab === "profile"
                ? 3
                : -1
        );
    }, [tab]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [selected, setSelected] = React.useState([]);
    const navigate = useNavigate();
    const isItemSelected = (id) => !!selected.find((el) => el === id);
    const [address, setAddress] = useState("");

    const handleClick =
        (id) =>
        ({ getItemById, scrollToItem }) => {
            const itemSelected = isItemSelected(id);

            setSelected((currentSelected) =>
                itemSelected
                    ? currentSelected.filter((el) => el !== id)
                    : currentSelected.concat(id)
            );
        };

    const handleChangeAddress = (address) => {
        setAddress(address);
    };

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const usersRef = firestore.collection("users");

    if (user) {
        query = usersRef.where("uid", "==", user.uid);
    }

    const [users] = useCollectionData(query, { idField: "id" });
    const location = useLocation();

    const handleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                return getLatLng(results[0]);
            })
            .then((latLng) => {})
            .catch((error) => {});
    };


    const [navbarTop, setNavbarTop] = useState(0);
    const [initialViewportHeight, setInitialViewportHeight] = useState(window.innerHeight);
  
    useEffect(() => {
      setInitialViewportHeight(window.innerHeight);
    }, []);
  
    useEffect(() => {
      const handleResize = () => {
        let viewportChange = initialViewportHeight - window.innerHeight;
  
        if (viewportChange > 0) {
          setNavbarTop(viewportChange);
        } else {
          setNavbarTop(0);
        }
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup listener on unmount
      return () => window.removeEventListener('resize', handleResize);
    }, [initialViewportHeight]);
    

    return (
        <>
            <div
                style={{
                    backgroundColor: "#2c2c2e",
                    marginBottom: "10px",
                    position: "fixed",
                    top: navbarTop,
                    width: "100%",
                    zIndex: 999999,
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent={
                            !user || windowWidth > 700
                                ? "space-between"
                                : "flex-end"
                        }
                        alignItems="center"
                        wrap="nowrap"
                        dense
                        sx={{ maxWidth: "750px" }}
                    >
                        {(!user || windowWidth > 700) && (
                            <img
                                onClick={() => {
                                    navigate("/");
                                }}
                                alt="Uniget logo"
                                src={logo}
                                style={{
                                    cursor: "pointer",
                                    width: "100px",
                                    margin: "20px 20px 10px 10px",
                                    filter: "grayscale(100%)",
                                    paddingLeft: "10px",
                                }}
                            />
                        )}
                        {user && (
                            <Tabs
                                size="small"
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                                sx={{
                                    overflowY: "auto",
                                    bgcolor: "background.paper",
                                }}
                            >
                                <Tab
                                    sx={{ fontSize: 16, py: "20px" }}
                                    onClick={() => navigate("/new")}
                                    label="🗣️ New"
                                    dense
                                    size="small"
                                />
                                <Tab
                                    sx={{ fontSize: 16, py: "20px" }}
                                    onClick={() => navigate("/chats")}
                                    label="💬 Chats"
                                    dense
                                    size="small"
                                />
                                <Tab
                                    sx={{ fontSize: 16, py: "20px" }}
                                    onClick={() => navigate("/requests")}
                                    label="🔄 Requests"
                                    dense
                                    size="small"
                                />
                                <Tab
                                    sx={{ fontSize: 16, py: "20px" }}
                                    onClick={() => navigate("/profile")}
                                    label="😁 Profile"
                                    dense
                                    size="small"
                                />
                            </Tabs>
                        )}
                        <div>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                wrap="nowrap"
                            >
                                {!user && <SignIn variant={"outlined"} />}
                                <Button
                                    sx={{
                                        ml: "5px",
                                    }}
                                    onClick={() => setIsDialogOpen(true)}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        wrap="nowrap"
                                        sx={{
                                            width: "55px",
                                            ml: "5px",
                                        }}
                                    >
                                        <img
                                            alt="chatgpt logo"
                                            src={chatgptLogo}
                                            style={{ width: "30px" }}
                                        />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                        >
                                            <div
                                                style={{
                                                    fontSize: "9px",
                                                    lineHeight: "9px",
                                                    marginLeft: "5px",
                                                    textAlign: "left",
                                                }}
                                            >
                                                Uniget ChatGPT Plugin
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Button>
                                <Tooltip title="menu">
                                    <IconButton onClick={handleClickMenu}>
                                        <MoreVert />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        "aria-labelledby": "long-button",
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    sx={{
                                        zIndex: 9999999,
                                    }}
                                    PaperProps={{
                                        style: {
                                            maxHeight: 48 * 4.5,
                                            width: "20ch",
                                        },
                                    }}
                                >
                                    {[
                                        // "About",
                                        "Contact / Feedback",
                                        "Build a Bot",
                                        "Terms of Service",
                                        "Privacy Policy",
                                        ...(user ? ["Log Out"] : []),
                                    ].map((option) => (
                                        <MenuItem
                                            key={option}
                                            selected={option === "Pyxis"}
                                            onClick={(e) =>
                                                handleClose(e, option)
                                            }
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Dialog
                open={isDialogOpen}
                onClose={() => {
                    setIsDialogOpen(false);
                }}
                sx={{ zIndex: 999999999999 }}
            >
                <DialogTitle>Install Uniget ChatGPT Plugin</DialogTitle>
                <IconButton
                    onClick={() => {
                        setIsDialogOpen(false);
                    }}
                    sx={{
                        position: "absolute",
                        right: "10px",
                        top: "10px",
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent
                    sx={{
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Box my={2}>
                            <Typography variant="h6" sx={{ fontSize: "18px" }}>
                                Step 1: Open Plugin Store
                            </Typography>
                            <Typography variant="body2">
                                Start by opening the Plugin Store. Please note
                                that to get access the ChatGPT plugins, you must
                                have a subscription to the premium version,
                                "ChatGPT Plus".
                            </Typography>
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/ultimate-2c4e3.appspot.com/o/Screenshot%202023-05-27%20at%2011.16.57.png?alt=media&token=62806cd3-b08e-42c1-99ce-efe709e64725"
                                alt="Plugin Store"
                                width="100%"
                            />
                        </Box>

                        <Divider />

                        <Box my={2}>
                            <Typography variant="h6" sx={{ fontSize: "18px" }}>
                                Step 2: Click on "Install an Unverified Plugin"
                            </Typography>
                            <Typography variant="body2">
                                Next, look for the option that says "Install an
                                Unverified Plugin".
                                <br />
                                <Typography
                                    variant="caption"
                                    sx={{
                                        fontStyle: "italic",
                                    }}
                                >
                                    The plugin is not verified yet because we
                                    just finished building it and it is still in
                                    the process of being reviewed by the ChatGPT
                                    team.
                                </Typography>
                            </Typography>
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/ultimate-2c4e3.appspot.com/o/Screenshot%202023-05-27%20at%2011.17.39.png?alt=media&token=a777f8b8-676c-4678-aace-8a0396a39fc7"
                                alt="install unverified plugin"
                                width="100%"
                            />
                        </Box>

                        <Divider />

                        <Box my={2}>
                            <Typography variant="h6" sx={{ fontSize: "18px" }}>
                                Step 3: Enter URL
                            </Typography>
                            <Typography variant="body2">
                                In the provided field, enter the URL of your
                                plugin. This should be the direct link to your
                                plugin's source code or download page.
                            </Typography>
                            <Typography variant="body1">
                                URL: <br /> {API_URL}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<FileCopy />}
                                onClick={handleCopyUrl}
                                sx={{
                                    mb: 2,
                                }}
                            >
                                Copy URL to Clipboard
                            </Button>
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/ultimate-2c4e3.appspot.com/o/Screenshot%202023-05-27%20at%2011.18.35.png?alt=media&token=863d025b-f803-47b0-a949-0f64ae38c19b"
                                alt="Enter URL"
                                width="100%"
                            />
                        </Box>

                        <Divider />

                        <Box
                            my={2}
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Typography variant="h6" sx={{ fontSize: "18px" }}>
                                Step 4: Enter Token
                            </Typography>
                            <Typography variant="body2">
                                After entering the URL, you will be asked to
                                enter a token and then click on "Install
                                Plugin".
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    whiteSpace: "initial",
                                    width: "100%",
                                }}
                            >
                                <span
                                    style={{
                                        width: "100%",
                                        wordWrap: "break-word",
                                        display: "inline-block",
                                    }}
                                >
                                    Token: {API_KEY}
                                </span>
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<FileCopy />}
                                onClick={handleCopyToken}
                                sx={{
                                    mb: 2,
                                }}
                            >
                                Copy Token to Clipboard
                            </Button>
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/ultimate-2c4e3.appspot.com/o/Screenshot%202023-05-27%20at%2011.18.57.png?alt=media&token=52292a65-fee4-4e7f-8564-29f942509590"
                                alt="Enter Token"
                                width="100%"
                            />
                        </Box>

                        <Box my={2}>
                            <Typography variant="h6" sx={{ fontSize: "18px" }}>
                                Step 5: Choose the Uniget Plugin
                            </Typography>
                            <Typography variant="body2">
                                Now your plugin should now be installed and
                                ready to use with ChatGPT. make sure it appears
                                and selected in the list of installed plugins.
                            </Typography>
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/ultimate-2c4e3.appspot.com/o/Screenshot%202023-05-27%20at%2011.19.17.png?alt=media&token=cb09d352-82eb-4e50-a505-96380ea68b7d"
                                alt="Result"
                                width="100%"
                            />
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <div style={{ height: 60 }}></div>
        </>
    );
}

function Home({ tab, isAdmin }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isAdressSelected, setIsAddressSeleceted] = useState(
        localStorage.getItem("mainAddress") ? true : false
    );
    const [visibleCount, setVisibleCount] = useState(20);
    const [visibleRequestCount, setVisibleRequestCount] = useState(20);

    const [filteredRequests, setFilteredRequests] = useState([]);
    const [mainAddress, setMainAddress] = useState(
        localStorage.getItem("mainAddress")
            ? JSON.parse(localStorage.getItem("mainAddress"))
            : null
    );

    const [askLocationFromBrowser, setAskLocationFromBrowser] = useState(false);

    useEffect(() => {
        if (mainAddress) {
            localStorage.setItem("mainAddress", JSON.stringify(mainAddress));
            setIsAddressSeleceted(true);
        }
    }, [mainAddress]);

    const [openAbout, setOpenAbout] = React.useState(false);
    firebase.auth().onAuthStateChanged(function (user) {
        if (!(user || localStorage.getItem("isAboutHidden") === "true")) {
            setOpenAbout(true);
        }
    });
    useEffect(() => {
        if (openAbout) {
            localStorage.setItem("isAboutHidden", "true");
        }
    }, [openAbout]);

    const messagesRef = firestore.collection("messages");
    const [user] = useAuthState(auth);
    const [generalRequestValue, setGeneralRequestValue] = useState("");
    const [adminRequests, setAdminRequests] = useState([]);
    let query = messagesRef.where("uid", "==", "asdfasdf").orderBy("createdAt");
    let assistQuery = messagesRef.orderBy("createdAt").limit(1);
    if (isAdmin) {
        assistQuery = messagesRef.orderBy("createdAt");
    }
    const [adminMessages] = useCollectionData(assistQuery, { idField: "id" });

    if (user) {
        query = messagesRef.where("uid", "==", user.uid).orderBy("createdAt");
    }

    const [myMessages] = useCollectionData(query, { idField: "id" });

    useEffect(() => {
        if (
            isAdmin &&
            adminMessages !== undefined &&
            adminMessages.length > 1
        ) {
            let ids = [];
            adminMessages.forEach((message) => {
                if (message.chatId && !ids.includes(message.chatId)) {
                    let filteredArray = adminMessages.filter(function (obj) {
                        return obj.chatId === message.chatId;
                    });
                    filteredArray.sort((a, b) =>
                        a.createdAt > b.createdAt ? -1 : 1
                    );
                    if (
                        filteredArray &&
                        filteredArray.length > 0 &&
                        filteredArray[0].uid !== "login"
                    ) {
                        ids.unshift({
                            id: message.chatId,
                            firstMessage: filteredArray[0].text,
                        });
                    }
                }
            });
            setAdminRequests(ids);
        }
    }, [adminMessages, isAdmin]);

    const [value, setValue] = React.useState(
        tab === "chats" ? 1 : tab === "requests" ? 2 : tab === "profile" ? 3 : 0
    );

    useEffect(() => {
        setValue(
            tab === "new"
                ? 0
                : tab === "chats"
                ? 1
                : tab === "requests"
                ? 2
                : tab === "profile"
                ? 3
                : -1
        );
    }, [tab]);

    const [selected, setSelected] = React.useState([]);
    const navigate = useNavigate();
    const isItemSelected = (id) => !!selected.find((el) => el === id);
    const [address, setAddress] = useState("");
    const [chats, setChats] = useState([]);
    const [noChats, setNoChats] = useState(false);
    const [noRequests, setNoRequests] = useState(false);
    useEffect(() => {}, [user]);

    const handleChangeAddress = (address) => {
        setAddress(address);
    };

    const usersRef = firestore.collection("users");
    const chatRoomRef = firestore.collection("chatRoom");

    if (user) {
        query = usersRef.where("uid", "==", user.uid);
    }

    const [users] = useCollectionData(query, { idField: "id" });
    const location = useLocation();

    const handleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                return getLatLng(results[0]);
            })
            .then((latLng) => {})
            .catch((error) => {});
    };

    const requestsRef = firestore.collection("requests");

    let requestQuery;

    if (user && user?.uid) {
        requestQuery = requestsRef
            .where("uid", "==", user.uid)
            .orderBy("createdAt", "desc");
    }

    useEffect(() => {
        if (user && user?.uid) {
            chatRoomRef
                .where("users", "array-contains", user.uid)
                .orderBy("lastMessageCreatedAt", "desc")
                .limit(visibleCount)
                .get()
                .then((querySnapshot) => {
                    if (querySnapshot.docs.length < 1) {
                        setNoChats(true);
                    }
                    setChats(querySnapshot.docs.map((doc) => doc.data()));
                })
                .catch((error) => {
                    alert(JSON.stringify(error));
                });
        }
    }, [user]);

    useEffect(() => {
        if (user && user?.uid) {
            chatRoomRef
                .where("users", "array-contains", user.uid)
                .orderBy("lastMessageCreatedAt", "desc")
                .limit(visibleCount)
                .get()
                .then((querySnapshot) => {
                    setChats(querySnapshot.docs.map((doc) => doc.data()));
                })
                .catch((error) => {});
        }
    }, [visibleCount]);

    const handleScroll = () => {
        const scrollPos = window.innerHeight + window.pageYOffset;
        const contentHeight = document.documentElement.scrollHeight;
        if (scrollPos >= contentHeight - 1000) {
            // Load 10 more posts when user scrolls to within 1000px of the bottom
            setVisibleCount((prevCount) => prevCount + 20);
        }
    };
    const handleScrollRequest = () => {
        const scrollPos = window.innerHeight + window.pageYOffset;
        const contentHeight = document.documentElement.scrollHeight;
        if (scrollPos >= contentHeight - 1000) {
            // Load 10 more posts when user scrolls to within 1000px of the bottom
            setVisibleRequestCount((prevCount) => prevCount + 20);
        }
    };

    useEffect(() => {
        if (user && user?.uid) {
            requestQuery
                .limit(visibleRequestCount)
                .get()
                .then((querySnapshot) => {
                    const requests = querySnapshot.docs.map((doc) =>
                        doc.data()
                    );
                    if (requests && requests.length > 0) {
                        setFilteredRequests(requests);
                    } else {
                        setNoRequests(true);
                    }
                })
                .catch((error) => {});
        }
    }, [user]);

    useEffect(() => {
        if (user && user?.uid) {
            requestQuery
                .limit(visibleRequestCount)
                .get()
                .then((querySnapshot) => {
                    const requests = querySnapshot.docs.map((doc) =>
                        doc.data()
                    );
                    if (requests && requests.length > 0) {
                        setFilteredRequests(requests);
                    }
                })
                .catch((error) => {});
        }
    }, [visibleRequestCount]);

    useEffect(() => {
        if (value === 1) {
            // Attach onScroll event to window object
            window.addEventListener("scroll", handleScroll);

            // Remove onScroll event when component unmounts
            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
        if (value === 2) {
            // Attach onScroll event to window object
            window.addEventListener("scroll", handleScrollRequest);

            // Remove onScroll event when component unmounts
            return () => {
                window.removeEventListener("scroll", handleScrollRequest);
            };
        }
    }, [value]);

    //key press listener
    useEffect(() => {
        const listener = (event) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                if (
                    document.location.href.includes("new") &&
                    generalRequestValue &&
                    generalRequestValue.length > 0
                ) {
                    navigate(
                        user && user?.uid
                            ? "/request" +
                                  "?id=" +
                                  uid() +
                                  "&q=" +
                                  generalRequestValue
                            : "/request" + "?q=" + generalRequestValue
                    );
                }
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [generalRequestValue]);

    return (
        <div style={{ paddingBottom: 120, height: "calc(100vh - 300px)" }}>
            <TopBar tab={tab} isAdmin={isAdmin} />
            <TabPanel value={value} index={0}>
                {/* {!isAdressSelected && ( */}
                <div style={{ marginTop: "80px", marginBottom: "15px" }}></div>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <div
                        style={{
                            maxWidth: 550,
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            style={{
                                width: "100%",
                                maxWidth: 1000,
                                margin: "auto",
                            }}
                        >
                            <Grid
                                xs={3}
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                sx={{}}
                            >
                                <div
                                    style={{
                                        textAlign: "center",
                                        fontSize: 30,
                                    }}
                                >
                                    🗣️
                                </div>

                                <Typography
                                    style={{
                                        textAlign: "center",
                                        padding: "10px",
                                        fontSize: 16,
                                    }}
                                >
                                    Ask the community for anything{" "}
                                </Typography>
                            </Grid>
                            <KeyboardDoubleArrowRightIcon sx={{ mt: "60px" }} />
                            <Grid
                                xs={3}
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                sx={{}}
                                justifySelf={"stretch"}
                            >
                                <div
                                    style={{
                                        textAlign: "center",
                                        fontSize: 30,
                                    }}
                                >
                                    🤖
                                </div>
                                <Typography
                                    style={{
                                        textAlign: "center",
                                        padding: "10px",
                                        fontSize: 16,
                                    }}
                                >
                                    AI matches with most relevant people, bots,
                                    businesses, and organizations
                                </Typography>
                            </Grid>
                            <KeyboardDoubleArrowRightIcon sx={{ mt: "60px" }} />
                            <Grid
                                xs={3}
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                sx={{}}
                            >
                                <div
                                    style={{
                                        textAlign: "center",
                                        fontSize: 30,
                                    }}
                                >
                                    🤝
                                </div>
                                <Typography
                                    style={{
                                        textAlign: "center",
                                        padding: "10px",
                                        fontSize: 16,
                                    }}
                                >
                                    Chat with the best users to get what you
                                    want{" "}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* <div
                            style={{
                                maxWidth: 1000,
                                margin: "auto",
                                marginTop: 70,
                                width: "100%",
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: "center",
                                    marginBottom: "15px",
                                }}
                            >
                                Examples
                            </Typography>
                        </div> */}
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="stretch"
                            spacing={4}
                            style={{
                                width: "100%",
                                maxWidth: 1200,
                                margin: "auto",
                                marginBottom: 120,
                            }}
                        >
                            {[
                                "Need a local electrician to install a ceiling fan",
                                "Find an online financial advisor to help with investment planning",
                                "Searching for a workout partner to stay motivated at the gym",
                                "Find a local assistant to help with daily errands and administrative tasks",
                                "Seeking travel buddies for a backpacking trip",
                                "Seeking AI developer",
                            ].map((item, index) => (
                                <Grid
                                    xs={3}
                                    spacing={2}
                                    sx={{
                                        // p: 2,
                                        marginRight: "5px",
                                        marginLeft: "5px",
                                        marginTop: "10px",
                                        minWidth: "150px",
                                    }}
                                    justifySelf={"stretch"}
                                    justifyContent={"space-between"}
                                >
                                    <Card
                                        sx={{
                                            cursor: "pointer",
                                            height: "100%",
                                            backgroundColor: "#434345",
                                            "&:hover": {
                                                backgroundColor: "#575759",
                                            },
                                        }}
                                        onClick={() => {
                                            setGeneralRequestValue(item);
                                        }}
                                    >
                                        <Typography
                                            style={{ textAlign: "center" }}
                                        >
                                            <i>"{item}"</i>
                                        </Typography>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </Grid>
                {1 + 1 === 3 && (
                    <div
                        style={{
                            // height: "calc(100% - 100px)",
                            height: "100%",
                            width: "calc(100vw - 8px)",
                            position: "absolute",
                            top: "  0px",
                            left: "0px",
                            fontSize: "50px",
                            backdropFilter: "blur(1px)",
                            backgroundColor: "rgba(0, 0, 0, 0.3)",
                            zIndex: 9,
                        }}
                    >
                        <div
                            style={{
                                position: "sticky",
                                top: "30vh",
                                textAlign: "center",
                            }}
                        >
                            <Grid
                                style={{ marginTop: 60 }}
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Card
                                    elevation={20}
                                    style={{
                                        background: "#2c2c2e",
                                        maxWidth: 638,
                                        width: "100%",
                                        padding: 20,
                                    }}
                                >
                                    <Typography variant="h5">
                                        Choose a location
                                        <Typography>
                                            to see services available in your
                                            area
                                        </Typography>
                                    </Typography>

                                    {askLocationFromBrowser && (
                                        <LocationFromBrowser
                                            onResopons={(res) => {
                                                setAddress(
                                                    res.data.results[0]
                                                        .formatted_address
                                                );
                                                setMainAddress(
                                                    res.data.results[0]
                                                );
                                                setIsAddressSeleceted(true);
                                                setAskLocationFromBrowser(
                                                    false
                                                );
                                                // onAddressSelected(
                                                //     res.data.results[0]
                                                // );
                                            }}
                                        />
                                    )}

                                    <div>
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={handleChangeAddress}
                                            onSelect={handleSelect}
                                        >
                                            {({
                                                getInputProps,
                                                suggestions,
                                                getSuggestionItemProps,
                                                loading,
                                            }) => (
                                                <div>
                                                    <Tooltip
                                                        style={{
                                                            position:
                                                                "relative",
                                                            paddingTop: -20,
                                                        }}
                                                        title={
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                                onClick={() => {
                                                                    setAskLocationFromBrowser(
                                                                        true
                                                                    );
                                                                }}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    fontSize: 20,
                                                                }}
                                                            >
                                                                <NearMeIcon />
                                                                <span
                                                                    style={{
                                                                        marginLeft: 10,
                                                                    }}
                                                                >
                                                                    Use my
                                                                    current
                                                                    location
                                                                </span>
                                                            </Grid>
                                                        }
                                                        placement="bottom-start"
                                                    >
                                                        <Paper
                                                            // component="form"
                                                            sx={{
                                                                m: "15px",
                                                                p: "2px 4px",
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                width: "calc(100% - 25px)",
                                                            }}
                                                        >
                                                            <IconButton
                                                                sx={{
                                                                    p: "10px",
                                                                }}
                                                                aria-label="menu"
                                                            >
                                                                <LocationOnIcon />
                                                            </IconButton>
                                                            <InputBase
                                                                {...getInputProps(
                                                                    {
                                                                        placeholder:
                                                                            "Choose a location for relevant services...",
                                                                        className:
                                                                            "location-search-input",
                                                                    }
                                                                )}
                                                                sx={{
                                                                    ml: 1,
                                                                    flex: 1,
                                                                }}
                                                                placeholder="Choose a location for relevant services..."
                                                                inputProps={{
                                                                    "aria-label":
                                                                        "Choose a location for relevant services...",
                                                                }}
                                                            />

                                                            <Button
                                                                color="primary"
                                                                variant="contained"
                                                                sx={{
                                                                    p: "10px",
                                                                }}
                                                                aria-label="directions"
                                                            >
                                                                Search
                                                            </Button>
                                                        </Paper>
                                                    </Tooltip>
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && (
                                                            <div>
                                                                Loading...
                                                            </div>
                                                        )}
                                                        {suggestions.map(
                                                            (suggestion) => {
                                                                const className =
                                                                    suggestion.active
                                                                        ? "suggestion-item--active"
                                                                        : "suggestion-item";
                                                                // inline style for demonstration purpose
                                                                const style =
                                                                    suggestion.active
                                                                        ? {
                                                                              backgroundColor:
                                                                                  "#fafafa",
                                                                              cursor: "pointer",
                                                                              fontSize:
                                                                                  "16px",
                                                                          }
                                                                        : {
                                                                              cursor: "pointer",
                                                                              fontSize:
                                                                                  "16px",
                                                                          };
                                                                return (
                                                                    <div
                                                                        {...getSuggestionItemProps(
                                                                            suggestion,
                                                                            {
                                                                                className,
                                                                                style,
                                                                            }
                                                                        )}
                                                                    >
                                                                        <span>
                                                                            {
                                                                                suggestion.description
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                </Card>
                            </Grid>
                        </div>
                    </div>
                )}
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {user && chats.length < 1 && !noChats && (
                        <CircularProgress
                            sx={{
                                zIndex: 1,
                                position: "absolute",
                                marginTop: "50px",
                            }}
                        />
                    )}
                    {noChats && user && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography
                                sx={{
                                    mt: 2,
                                    color: "#fff",
                                    textAlign: "center",
                                    display: "block",
                                    margin: "auto",
                                }}
                            >
                                No chats yet
                            </Typography>
                        </Grid>
                    )}
                    {user ? (
                        <div
                            style={{
                                width: "100%",
                                maxWidth: 638,
                                zIndex: 100,
                                background: "#2c2c2e",
                            }}
                        >
                            {chats && chats?.length > 0 ? (
                                chats?.map((chat) => {
                                    return (
                                        <ChatListItem
                                            style={{
                                                zIndex: 100,
                                                background: "#2c2c2e",
                                            }}
                                            key={chat?.chatRoomId}
                                            chatId={chat?.chatRoomId}
                                            lastMessage={chat?.lastMessage}
                                        />
                                    );
                                })
                            ) : (
                                <></>
                            )}
                        </div>
                    ) : (
                        <div style={{ marginTop: 25 }}>
                            <SignIn text={"Sign In to continue"} />
                        </div>
                    )}
                </Grid>
            </TabPanel>

            <TabPanel value={value} index={2}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ width: "100%" }}
                >
                    {user && filteredRequests.length < 1 && !noRequests && (
                        <CircularProgress
                            sx={{
                                zIndex: 1,
                                position: "absolute",
                                marginTop: "40px",
                            }}
                        />
                    )}

                    {noRequests && user && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography
                                sx={{
                                    mt: 2,
                                    color: "#fff",
                                    textAlign: "center",
                                    display: "block",
                                    margin: "auto",
                                }}
                            >
                                No Requests yet
                            </Typography>
                        </Grid>
                    )}

                    {user ? (
                        <div
                            style={{
                                width: "100%",
                                maxWidth: 638,
                                zIndex: 100,
                                background: "#2c2c2e",
                            }}
                        >
                            <div style={{ width: "100%", maxWidth: 638 }}>
                                {filteredRequests &&
                                    filteredRequests?.map((request) => {
                                        return (
                                            <div
                                                onClick={() =>
                                                    navigate(
                                                        user && user?.uid
                                                            ? "/request" +
                                                                  "?id=" +
                                                                  request.requestId +
                                                                  "&q=" +
                                                                  request.query
                                                            : "/request" +
                                                                  "?q=" +
                                                                  request.query
                                                    )
                                                }
                                                style={{
                                                    borderBottom:
                                                        "1px solid rgba(255, 255, 255, 0.12)",
                                                    cursor: "pointer",
                                                    paddingTop: 10,
                                                    paddingBottom: 10,
                                                    width: "100%",
                                                }}
                                            >
                                                <Grid
                                                    direction={"row"}
                                                    container
                                                    justifyContent={
                                                        "flex-start"
                                                    }
                                                    alignItems={"center"}
                                                    wrap={"nowrap"}
                                                >
                                                    <div
                                                        style={{
                                                            marginRight: "15px",
                                                            marginLeft: "15px",
                                                        }}
                                                    >
                                                        <CampaignIcon />
                                                    </div>
                                                    <div>{request.query}</div>
                                                </Grid>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    ) : (
                        <div style={{ marginTop: 25 }}>
                            <SignIn text={"Sign In to continue"} />
                        </div>
                    )}
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Profile />
            </TabPanel>
            {location.pathname.includes("/new") ? (
                <Paper
                    elevation={20}
                    sx={{
                        zIndex: 99999,
                        position: "fixed",
                        bottom: 0,
                        width: "100%",
                        background: "#2c2c2e",
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        wrap="nowrap"
                        style={{ width: "100%", marginBottom: 30 }}
                    >
                        <div
                            style={{
                                width: "100%",
                                padding: 35,
                                maxWidth: 668,
                            }}
                        >
                            <form
                                onSubmit={() => {
                                    navigate(
                                        user && user?.uid
                                            ? "/request" +
                                                  "?id=" +
                                                  uid() +
                                                  "&q=" +
                                                  generalRequestValue
                                            : "/request" +
                                                  "?q=" +
                                                  generalRequestValue
                                    );
                                }}
                            >
                                <TextField
                                    id="outlined-basic"
                                    placeholder="Ask the community anything..."
                                    variant="outlined"
                                    value={generalRequestValue}
                                    autoFocus
                                    autoComplete="off"
                                    type="text"
                                    inputProps={{ autoComplete: "off" }}
                                    focused
                                    onChange={(e) => {
                                        setGeneralRequestValue(e.target.value);
                                    }}
                                    style={{ width: "calc(100% - 110px)" }}
                                />

                                <Button variant="contained" type="submit">
                                    <SendIcon />
                                </Button>
                            </form>
                        </div>
                    </Grid>
                </Paper>
            ) : null}
        </div>
    );
}

function Request() {
    const [user] = useAuthState(auth);

    const navigate = useNavigate();
    const location = useLocation();
    const [url, setUrl] = useState(new URL(window.location.href));
    const [query, setQuery] = useState(url.searchParams.get("q") || "");
    const [request, setRequest] = useState();
    const [results, setResults] = useState([]);
    const [requestId, setRequestId] = useState(
        url.searchParams.get("id") || ""
    );

    useEffect(() => {
        setUrl(new URL(window.location.href));
        setQuery(url.searchParams.get("q") || "");
        setRequestId(url.searchParams.get("id") || "");
    }, [location]);

    const requestsRef = firestore.collection("requests");
    const requestQuery = requestsRef
        .where("requestId", "==", requestId)
        .orderBy("createdAt");

    const [requests] = useCollectionData(requestQuery, {
        idField: "requestId",
    });

    useEffect(() => {
        if (requests && requests.length > 0) {
            setRequest(requests[0]);
        }
    }, [requests]);

    const getRelevatUsers = async () => {
        try {
            const response = await axios.post(
                API_URL + "/query",
                {
                    queries: [
                        {
                            query: query,
                        },
                    ],
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: "Bearer " + API_KEY,
                    },
                }
            );
            const x = response.data.results[0].results.map((result) => {
                let uid = "";
                let uidRegex = /"uid":"(.*?)"/;
                let match = result.text.match(uidRegex);
                if (match) {
                    uid = match[1];
                }
                let description = "";
                let descriptionRegex = /"description":"(.*?)"/;
                match = result.text.match(descriptionRegex);
                if (match) {
                    description = match[1];
                }
                let reason_name = "";
                let reason_nameRegex = /"reason_name":"(.*?)"/;
                match = result.text.match(reason_nameRegex);
                if (match) {
                    reason_name = match[1];
                }
                return { uid, description, reason_name };
            });

            // remove duplicate users by uid
            const unique = x.filter(
                (thing, index, self) =>
                    index === self.findIndex((t) => t.uid === thing.uid)
            );

            //remove empty strings
            if (user && user?.uid) {
                unique.forEach((item, index) => {
                    if (item.uid === "") {
                        unique.splice(index, 1);
                    }
                });
            }

            setResults(unique);
            mixpanel.track("Request", {
                query: query,
                results: unique,
            });
            return unique;
        } catch (error) {
            setResults([{}]);
            return [{}];
        }
    };

    const chatRoomRef = firestore.collection("chatRoom");
    const messagesRef = firestore.collection("messages");

    const attachChatToRequest = async () => {
        //TODO: replace with real api call
        const relevantUsers = await getRelevatUsers();

        if (user && user?.uid) {
            relevantUsers.forEach(async (user) => {
                let chatId = "";

                const querySnapshot = await chatRoomRef
                    .where("users", "array-contains", user.uid)
                    .get();

                querySnapshot.forEach((doc) => {
                    if (doc.data().users.includes(user.uid)) {
                        chatId = doc.data().chatRoomId;
                    }
                });

                if (chatId.length < 1) {
                    chatId = uid(10);
                    await chatRoomRef.add({
                        users: [user.uid, user.uid],
                        chatRoomId: chatId,
                    });
                }

                const requestQuery = requestsRef.where(
                    "requestId",
                    "==",
                    requestId
                );
                requestQuery
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            // Update the document
                            doc.ref
                                .update({
                                    chats: doc.data().chats
                                        ? [...doc.data().chats, chatId]
                                        : [chatId],
                                    otherUserDescriptions: doc.data()
                                        .otherUserDescriptions
                                        ? [
                                              ...doc.data()
                                                  .otherUserDescriptions,
                                              {
                                                  chatId: chatId,
                                                  description: user.description,
                                              },
                                          ]
                                        : [
                                              {
                                                  chatId: chatId,
                                                  description: user.description,
                                              },
                                          ],
                                })
                                .then((x) => {});
                        });
                    })
                    .catch((error) => {});
            });
        }
    };

    useEffect(() => {
        return () => {
            documentAdded = false;
        };
    }, []);

    useEffect(() => {
        const addRequest = async () => {
            requestQuery.get().then((querySnapshot) => {
                if (!(querySnapshot.docs.length >= 1) && !documentAdded) {
                    documentAdded = true;
                    requestsRef
                        .add({
                            requestId: requestId,
                            query: query,
                            createdAt:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            uid: user.uid,
                        })
                        .then(() => {
                            attachChatToRequest();
                        });
                }
            });
        };
        if (user && user.uid) {
            addRequest();
        } else {
            getRelevatUsers();
        }
        //get chatrooms that include my user id
    }, [user]);

    return (
        <div>
            <TopBar />
            <div
                style={{
                    width: "100%",
                    maxWidth: 668,

                    margin: "auto",
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    wrap="nowrap"
                >
                    {/* <IconButton
                        onClick={() => {
                            goBackOrHome();
                        }}
                        sx={{ margin: "5px" }}
                    >
                        <ArrowBackIos />
                    </IconButton> */}
                    <Typography
                        variant="h6"
                        sx={{
                            p: 0,
                            m: 0,
                            fontWeight: 700,
                            py: 1,
                            width: "100%",
                            mt: 4,
                            pl: "20px",
                        }}
                    >
                        {results?.length > 0
                            ? `Found ${results?.length} users matching `
                            : "Searching users matching "}
                        <span
                            style={{
                                fontSize: 18,
                                fontWeight: 400,
                            }}
                        >
                            "{query}"
                        </span>
                    </Typography>
                </Grid>
            </div>

            <Grid
                direction={"column"}
                container
                style={{
                    width: "100%",
                    maxWidth: 668,
                    margin: "auto",
                    marginTop: "20px",
                }}
            >
                {request?.chats?.length > 0 && (
                    <div>
                        <div style={{ marginTop: "10px" }}>
                            {request?.chats?.map((chatId) => {
                                return (
                                    <ChatListItem
                                        query={query}
                                        key={chatId}
                                        chatId={chatId}
                                        description={
                                            request.otherUserDescriptions.find(
                                                (x) => x.chatId === chatId
                                            )?.description
                                        }
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}

                {!user && results?.length > 0 && (
                    <div>
                        <div style={{ marginTop: "10px" }}>
                            {results.map((user, i) => {
                                return (
                                    <ChatListItem
                                        query={query}
                                        chatId={""}
                                        key={user.uid + i}
                                        userIdProp={user.uid}
                                        description={user.description}
                                        reason_name={user.reason_name}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}
                {(!request?.chats || request?.chats?.length < 1) &&
                !(!user && results?.length > 0) ? (
                    <Grid
                        direction={"column"}
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            width: "100%",
                            maxWidth: 1000,
                            margin: "auto",
                            marginTop: "50px",
                        }}
                    >
                        <LinearProgress
                            sx={{
                                width: "300px",
                                animationDuration: "20s",
                                m: 2,
                            }}
                        />

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-end"
                            wrap="nowrap"
                        >
                            <Typography variant="body1" sx={{ p: 0, m: 0 }}>
                                Looking for best users to your request
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                        wrap="nowrap"
                    >
                        <Typography
                            variant="caption"
                            sx={{ p: 0, m: 2, fontSize: "12px" }}
                        >
                            * We are currently in Beta. let us know how you
                            found the experience by{" "}
                            <Button href="/contact" sx={{ p: 0, m: 0 }}>
                                contacting us
                            </Button>
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

function ChatListItem({
    chatId,
    query,
    userIdProp,
    lastMessage,
    description,
    reason_name,
}) {
    const [user] = useAuthState(auth);
    const [chat, setChat] = useState();
    const [messageToSend, setMessageToSend] = useState();
    const [messages, setMessages] = useState();
    const [otherUserId, setOtherUserId] = useState();
    const [otherUser, setOtherUser] = useState();
    const navigate = useNavigate();
    const chatRoomRef = firestore.collection("chatRoom");
    const messagesRef = firestore.collection("messages");
    const usersRef = firestore.collection("users");
    const chatQuery = chatRoomRef.where("chatRoomId", "==", chatId || "");

    const [chats] = useCollectionData(chatQuery, {
        idField: "chatRoomId",
    });

    useEffect(() => {
        if (chats && chats.length > 0) {
            setChat(chats[0]);
        }
    }, [chats]);

    useEffect(() => {
        if (chat) {
            const otherUser = chat.users.find((x) => x !== user.uid);
            setOtherUserId(otherUser);
        }
    }, [chat]);

    useEffect(() => {
        if (!otherUserId && !userIdProp) return;
        usersRef
            .where("uid", "==", otherUserId || userIdProp)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    setOtherUser(doc.data());
                });
            });
    }, [otherUserId]);

    return (
        // <div>Hi how are you?</div>
        <div
            style={{
                paddingLeft: "10px",
                paddingRight: "10px",
            }}
        >
            <Grid
                direction={"row"}
                container
                justifyContent="space-between"
                alignItems="center"
            >
                {otherUser ? (
                    <ListItem
                        onClick={() => {
                            if (chatId) {
                                navigate(
                                    `/chat?id=${chatId}` +
                                        (query ? `&query=${query}` : "")
                                );
                            } else {
                                navigate(
                                    `/chat?userId=${
                                        otherUserId || userIdProp
                                    }` + (query ? `&query=${query}` : "")
                                );
                            }
                        }}
                        sx={{ cursor: "pointer" }}
                        divider={!query}
                        dense
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems={"flex-start"}
                            wrap="nowrap"
                        >
                            <ListItemAvatar fontSize="large">
                                {otherUser?.name &&
                                otherUser?.name.length > 0 ? (
                                    <Avatar
                                        alt={otherUser?.name}
                                        src={otherUser?.photoURL}
                                    />
                                ) : (
                                    <AccountCircle fontSize="large" />
                                )}
                            </ListItemAvatar>
                            <Grid container direction="column">
                                <Typography sx={{ m: 0, p: 0 }}>
                                    {otherUser?.name?.length > 0
                                        ? otherUser?.name
                                        : "Name not Added"}
                                </Typography>
                                {description && (
                                    <Typography variant="caption">
                                        {description}
                                    </Typography>
                                )}
                                <Typography variant="caption">
                                    {!query && lastMessage}{" "}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItem>
                ) : (
                    <></>
                )}
                {query && query.length > 0 && otherUser && (
                    <Grid
                        direction={"row"}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        wrap="nowrap"
                        sx={{ px: 2, marginBottom: "10px" }}
                    >
                        <TextField
                            defaultValue={query}
                            sx={{ py: 0 }}
                            fullWidth
                            dense
                            size="small"
                            onChange={(e) => {
                                setMessageToSend(e.target.value);
                            }}
                        ></TextField>
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            sx={{ marginLeft: "10px" }}
                            onClick={async () => {
                                if (user && user?.uid) {
                                    await messagesRef.add({
                                        chatId,
                                        text:
                                            messageToSend?.length > 0
                                                ? messageToSend
                                                : query,
                                        createdAt:
                                            firebase.firestore.FieldValue.serverTimestamp(),
                                        uid: user.uid,
                                    });
                                    await chatRoomRef
                                        .where("chatRoomId", "==", chatId)
                                        .get()
                                        .then((querySnapshot) => {
                                            querySnapshot.forEach((doc) => {
                                                doc.ref.update({
                                                    lastMessage:
                                                        messageToSend?.length >
                                                        0
                                                            ? messageToSend
                                                            : query,
                                                    lastMessageCreatedAt:
                                                        firebase.firestore.FieldValue.serverTimestamp(),
                                                });
                                            });
                                        });

                                    sendEmail(
                                        otherUserId,
                                        messageToSend?.length > 0
                                            ? messageToSend
                                            : query
                                    );
                                    mixpanel.track("Message Sent", {
                                        chatId: chatId,
                                        createdAt:
                                            firebase.firestore.FieldValue.serverTimestamp(),
                                        message:
                                            messageToSend?.length > 0
                                                ? messageToSend
                                                : query,
                                    });
                                    navigate(`/chat?id=${chatId}`);
                                } else {
                                    navigate(
                                        `/chat?userId=${userIdProp}&query=${
                                            messageToSend?.length > 0
                                                ? messageToSend
                                                : query
                                        }`
                                    );
                                }
                            }}
                        >
                            send
                        </Button>
                    </Grid>
                )}
            </Grid>
            {query && query.length > 0 && otherUser && <Divider />}
        </div>
    );
}

function Chat() {
    const navigate = useNavigate();
    const location = useLocation();
    const [user] = useAuthState(auth);
    const [url, setUrl] = useState(new URL(window.location.href));
    const [chatId, setChatId] = useState(url.searchParams.get("id") || "");
    const [otherUserId, setOtherUserId] = useState(
        url.searchParams.get("userId") || ""
    );
    const [query, setQuery] = useState(url.searchParams.get("query") || "");
    const usersRef = firestore.collection("users");
    const [filled, setFilled] = useState(url.searchParams.get("filled") || "");
    const chatsRef = firestore.collection("chatRoom");
    useEffect(() => {
        setUrl(new URL(window.location.href));
        setChatId(url.searchParams.get("id") || "");
        setOtherUserId(url.searchParams.get("userId") || "");
        setQuery(url.searchParams.get("query") || "");
    }, [location]);

    useEffect(() => {
        if (filled === "true" && chatId.length < 1) {
            const currentUrl = new URL(window.location.href);
            const rel = currentUrl
                .toString()
                .substring(currentUrl.origin.length);
            if (!currentUrl.toString().includes("&id=")) {
                navigate(rel + "&id=" + uid());
            }
        }
    }, [filled]);

    useEffect(() => {
        if (otherUserId && !chatId && user?.uid) {
            mixpanel.track("chat", {
                userId: user?.uid,
                otherUserId,
            });

            chatsRef
                .where("users", "array-contains", otherUserId)
                .get()
                .then((querySnapshot) => {
                    let isChatIdSet = false;
                    querySnapshot.forEach((doc) => {
                        if (doc?.data()?.users?.includes(user?.uid)) {
                            setChatId(doc.data().chatRoomId);
                            isChatIdSet = true;
                        }
                    });
                    if (!isChatIdSet) {
                        const newChatId = uid();
                        chatsRef
                            .add({
                                users: [user.uid, otherUserId],
                                chatRoomId: newChatId,
                            })
                            .then((querySnapshot) => {
                                setChatId(newChatId);
                            });
                    }
                });
        } else {
            if (chatId) {
                mixpanel.track("chat", {
                    userId: user?.uid,
                    chatId,
                });
            }
        }
    }, [otherUserId, user]);

    useEffect(() => {
        if (chatId && chatId.length > 0 && !otherUserId && user?.uid) {
            chatsRef
                .where("chatRoomId", "==", chatId)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        if (doc.data().users[0] !== user?.uid) {
                            setOtherUserId(doc.data().users[0]);
                        } else {
                            setOtherUserId(doc.data().users[1]);
                        }
                    });
                });
        }
    }, [chatId, otherUserId, user]);

    return (
        <div>
            <Grid
                container
                direction={"column"}
                justifyContent={"stretch"}
                alignItems={"center"}
            >
                <div style={{ width: "100%" }}>
                    {" "}
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            zIndex: 999999,
                            width: "100%",
                            background: "#2c2c2e",
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                maxWidth: 668,
                                display: "block",
                                margin: "auto",
                            }}
                        >
                            <ChatHeader chatId={chatId} userId={otherUserId} />
                        </div>
                    </div>
                    <div style={{ height: 55 }}></div>
                    <Divider />
                </div>
                <div style={{ width: "100%", maxWidth: 668 }}>
                    <section>
                        <ChatRoom
                            chatId={chatId}
                            defaultQuery={query}
                            otherUserId={otherUserId}
                        />
                    </section>
                </div>
            </Grid>
        </div>
    );
}

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
        React.useContext(VisibilityContext);

    return (
        <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            <ArrowBackIos />
        </Arrow>
    );
}

function RightArrow() {
    const { isLastItemVisible, scrollNext } =
        React.useContext(VisibilityContext);

    return (
        <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
            <ArrowForwardIos />
        </Arrow>
    );
}

function InfoCard({ card }) {
    const visibility = React.useContext(VisibilityContext);
    const navigate = useNavigate();

    return (
        <Card
            elevation={20}
            sx={{
                width: "calc(100vw - 70px)",
                maxWidth: "586px",
                backgroundImage: `url(${card.image})`,
                backgroundSize: "cover",
                mr: 2,
                cursor: "pointer",
                height: 370,
                position: "relative",
            }}
        >
            <CardContent
                sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    background:
                        "linear-gradient(0deg, rgba(0,0,0, 0.8) 0%, rgba(0,0,0, 0.4) 90%, rgba(0,0,0, 0) 100% )",
                }}
            >
                <Typography
                    variant="h5"
                    component="h2"
                    style={{
                        color: "white",
                        textShadow: "2px 2px 4px #000000",
                    }}
                >
                    {card.title}
                </Typography>
                <Typography
                    variant="body1"
                    component="p"
                    style={{
                        minHeight: "150px",
                        color: "white",
                        textShadow: "2px 2px 4px #000000",
                    }}
                >
                    {card.description}
                </Typography>
            </CardContent>
        </Card>

        // <Card
        //     onClick={(e) => {
        //         window.open('/about','_blank');
        //     }}
        //     sx={{
        //         width: "786px",
        //         maxWidth: "calc(100% - 50px)",
        //         mr: 2,
        //         cursor: "pointer",
        //     }}
        // >
        //     <CardMedia
        //         component="img"
        //         height="419"
        //         image={card.image}
        //         alt="green iguana"
        //     />
        //     <CardContent>
        //         <Typography gutterBottom variant="h5" component="div">
        //             bla bla
        //         </Typography>
        //         <Typography variant="body2" color="text.secondary">
        //             bla bla
        //         </Typography>
        //     </CardContent>
        // </Card>
    );
}

function ServiceCard({ onClick, selected, title, itemId, description, image }) {
    const visibility = React.useContext(VisibilityContext);
    const navigate = useNavigate();

    return (
        <Card
            onClick={(e) => {
                navigate("/chat" + "?q=" + title + "&service=" + itemId);
            }}
            sx={{ width: 250, mr: 2, cursor: "pointer" }}
        >
            <CardMedia
                component="img"
                height="140"
                image={image}
                alt="green iguana"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
}

function Arrow({ children, disabled, onClick }) {
    return (
        <div
            disabled={disabled}
            onClick={onClick}
            style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                right: "1%",
                opacity: disabled ? "0" : "1",
                userSelect: "none",
            }}
        >
            {children}
        </div>
    );
}

function SignIn({ text, variant }) {
    const [isSignedIn, setIsSignedIn] = useState(-1);

    useEffect(() => {
        const unregisterAuthObserver = firebase
            .auth()
            .onAuthStateChanged((user) => {
                setIsSignedIn(!!user);
            });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider);
    };

    if (isSignedIn === -1) {
        return null; // or return a different component to indicate the user is signed in
    }

    return (
        <Button
            sx={{
                py: "5px",
            }}
            size="small"
            variant={variant || "contained"}
            className="sign-in"
            onClick={signInWithGoogle}
        >
            {text ? text : "Sign in with Google"}
        </Button>
    );
}
const useScrollToBottomOnElementHeightChange = () => {
    const elementRef = useRef(null);

    useEffect(() => {
        if (elementRef.current) {
            const resizeObserver = new ResizeObserver(() => {
                const element = elementRef.current;
                element.scrollTop = element.scrollHeight;
            });

            resizeObserver.observe(elementRef.current);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [elementRef]);
    return elementRef;
};

function ChatRoom({ chatId, defaultQuery, otherUserId }) {
    const dummy = useRef();
    const navigate = useNavigate();
    const [allowedUser, setAllowedUser] = useState(false);
    const ref = useScrollToBottomOnElementHeightChange();

    const [user] = useAuthState(auth);
    const messagesRef = firestore.collection("messages");
    const chatRoomRef = firestore.collection("chatRoom");

    const chatRoomQuery = chatRoomRef.where("chatRoomId", "==", chatId);

    const query = messagesRef
        .where("chatId", "==", chatId)
        .orderBy("createdAt");

    const [messages] = useCollectionData(query, { idField: "chatId" });
    const [chats] = useCollectionData(chatRoomQuery, { idField: "chatRoomId" });

    useEffect(() => {
        if (chats && chats.length > 0 && chats[0] && chats[0].users) {
            if (!chats[0].users.includes(user?.uid)) {
                setAllowedUser(false);
            } else {
                setAllowedUser(true);
            }
        }
    }, [chats]);
    const [formValue, setFormValue] = useState("");

    useEffect(() => {
        const sendInitialQuery = async () => {
            let uid = "no-id-yet";
            let photoURL = "no-photo-yet";
            setFormValue("");
            dummy.current.scrollIntoView({ behavior: "smooth" });
        };
        if (messages !== undefined && messages.length < 1) {
            sendInitialQuery();
        }
    }, [messages]);

    useEffect(() => {
        if (defaultQuery && defaultQuery.length > 0 && formValue.length < 1) {
            setFormValue(defaultQuery);
        }
    }, [defaultQuery]);

    const sendMessage = async (e) => {
        e.preventDefault();
        const { uid, photoURL } = auth.currentUser;
        if (formValue && formValue.length > 0) {
            await messagesRef.add({
                text: formValue,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid: uid,
                photoURL,
                chatId,
            });

            setFormValue("");
            await chatRoomRef
                .where("chatRoomId", "==", chatId)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        doc.ref.update({
                            lastMessage: formValue,
                            lastMessageCreatedAt:
                                firebase.firestore.FieldValue.serverTimestamp(),
                        });
                    });
                });
            sendEmail(otherUserId, formValue);
            mixpanel.track("Message Sent", {
                chatId: chatId,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                message: formValue,
            });

            dummy?.current?.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        if (messages && messages.length > 0) {
            setTimeout(() => {
                dummy?.current?.scrollIntoView({ behavior: "smooth" });
            }, 500);
        }
    }, [messages]);

    return (
        <>
            <div
                style={{
                    background: "#2c2c2e",
                    position: "fixed",
                    bottom: 0,
                    zIndex: 99999999,
                    height: "10px",
                    width: "100%",
                }}
            ></div>

            <Fragment ref={ref}>
                {allowedUser && (
                    <main
                        style={{
                            paddingBottom: "500px",
                            maxHeight: "calc(100vh - 160px)",
                        }}
                    >
                        {messages &&
                            messages.map((msg) => (
                                <ChatMessage key={msg.id} message={msg} />
                            ))}
                        <span ref={dummy}></span>

                        <div
                            style={{
                                background: "#2c2c2e",
                                height: "500px",
                                width: "100%",
                                marginTop: "10px",
                            }}
                        >
                            <br />
                            <br />
                            <br />
                        </div>
                    </main>
                )}
                {!user && (
                    <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{ mt: 4 }}
                    >
                        <SignIn text="Sign in to chat" />
                    </Grid>
                )}
                <div>
                    <form
                        onSubmit={sendMessage}
                        style={{
                            background: "#2c2c2e",
                            position: "fixed",
                            bottom: "10px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            zIndex: 9999999,
                        }}
                    >
                        <TextField
                            id="outlined-basic"
                            placeholder="Type something..."
                            variant="outlined"
                            focused
                            autoFocus
                            value={formValue}
                            defaultValue={defaultQuery}
                            onChange={(e) => setFormValue(e.target.value)}
                            style={{ width: "calc(100% - 70px)" }}
                        />

                        <Button variant="contained" type="submit">
                            <SendIcon />
                        </Button>
                    </form>
                </div>
            </Fragment>
        </>
    );
}

function ChatMessage(props) {
    const { text, uid, photoURL } = props.message;

    const messageClass =
        auth.currentUser && uid === auth.currentUser.uid
            ? "sent"
            : uid === "no-id-yet"
            ? "sent"
            : "received";
    return (
        <>
            <div className={`message ${messageClass}`}>
                {uid === "login" ? (
                    <SignIn text="Please sign in to continue" />
                ) : (
                    <>
                        <p>{text}</p>
                    </>
                )}
            </div>
        </>
    );
}

const useAudio = (url) => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
        audio.addEventListener("ended", () => setPlaying(false));
        return () => {
            audio.removeEventListener("ended", () => setPlaying(false));
        };
    }, []);

    return [playing, toggle];
};

const Player = ({ url }) => {
    const [playing, toggle] = useAudio(url);

    return (
        <Tooltip title={playing ? "Pause" : "Play"}>
            <IconButton onClick={toggle}>
                {playing ? <VolumeOff /> : <PlayArrow />}
            </IconButton>
        </Tooltip>
    );
};

function getTimeAndOffset(timezoneString) {
    const [tzIdentifier, utcOffset] = timezoneString.split(" ");
    const now = new Date();
    const currentTimestamp =
        now.getTime() + now.getTimezoneOffset() * 60 * 1000;
    const [offsetHours, offsetMinutes] = utcOffset
        .split(":")
        .map((s) =>
            s.startsWith("+") ? parseInt(s.slice(1)) : -parseInt(s.slice(1))
        );
    const localTimestamp =
        currentTimestamp +
        offsetHours * 60 * 60 * 1000 +
        offsetMinutes * 60 * 1000;
    const localTime = new Date(localTimestamp);

    const hh = String(localTime.getHours()).padStart(2, "0");
    const mm = String(localTime.getMinutes()).padStart(2, "0");
    const dd = String(localTime.getDate()).padStart(2, "0");
    const MM = String(localTime.getMonth() + 1).padStart(2, "0");
    const yyyy = localTime.getFullYear();

    return `${hh}:${mm}, ${dd}/${MM}/${yyyy}, (${utcOffset} UTC)`;
}

function ChatHeader({ chatId, userId, isOnProfile }) {
    const [user] = useAuthState(auth);
    const [chat, setChat] = useState();
    const [messages, setMessages] = useState();
    const [lastMessage, setLastMessage] = useState();
    const [otherUserId, setOtherUserId] = useState(userId);
    const [otherUser, setOtherUser] = useState();
    const navigate = useNavigate();

    const chatRoomRef = firestore.collection("chatRoom");
    const messagesRef = firestore.collection("messages");
    const usersRef = firestore.collection("users");

    const chatQuery = chatRoomRef.where("chatRoomId", "==", chatId);
    const messagesQuery = messagesRef
        .where("chatId", "==", chatId)
        .orderBy("createdAt", "desc")
        .limit(1);

    const [chats] = useCollectionData(chatQuery, {
        idField: "chatRoomId",
    });

    const [messagesData] = useCollectionData(messagesQuery, {
        idField: "messageId",
    });

    useEffect(() => {
        if (chats && chats.length > 0) {
            setChat(chats[0]);
        }
    }, [chats]);

    useEffect(() => {
        if (messagesData && messagesData.length > 0) {
            setMessages(messagesData);
            setLastMessage(messagesData[messagesData.length - 1]);
        }
    }, [messagesData]);

    useEffect(() => {
        if (chat) {
            const otherUser = chat.users.find((x) => x !== user.uid);
            setOtherUserId(otherUser);
        }
    }, [chat]);

    useEffect(() => {
        if (!otherUserId) return;
        usersRef
            .where("uid", "==", otherUserId)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    setOtherUser(doc.data());
                });
            });
    }, [otherUserId]);

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems={"center"}
                wrap="nowrap"
                sx={{ minHeight: "55px" }}
            >
                <IconButton
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    onClick={() => {
                        goBackOrHome();
                    }}
                >
                    <ArrowBackIos sx={{ p: 0, m: 0 }} />
                </IconButton>
                {chat || otherUser ? (
                    <ListItem
                        onClick={() => {
                            navigate(`/chat?id=${chatId}`);
                        }}
                        dense
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems={"center"}
                            wrap="nowrap"
                            sx={{
                                py: 1,
                            }}
                        >
                            {!isOnProfile && (
                                <ListItemAvatar
                                    fontSize="large"
                                    href={`/user?id=${otherUser?.uid}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`/user?id=${otherUser?.uid}`);
                                    }}
                                >
                                    {otherUser?.photoURL &&
                                    otherUser?.photoURL.length > 0 ? (
                                        <Avatar
                                            sx={{
                                                "& .MuiListItemAvatar-root": {},
                                            }}
                                            alt={otherUser?.displayName}
                                            src={otherUser?.photoURL}
                                        />
                                    ) : (
                                        <AccountCircle fontSize="large" />
                                    )}
                                </ListItemAvatar>
                            )}
                            <Button
                                disableRipple
                                disableFocusRipple
                                disableTouchRipple
                                href={`/user?id=${otherUser?.uid}`}
                                sx={{
                                    m: 0,
                                    p: 1,
                                    position: "relative",
                                    display: "inline",
                                    width: "auto",
                                }}
                            >
                                {otherUser?.name?.length > 0
                                    ? otherUser?.name
                                    : "Name not added"}
                            </Button>
                        </Grid>
                    </ListItem>
                ) : (
                    <></>
                )}
            </Grid>
        </div>
    );
}

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
        borderRadius: 22 / 2,
        "&:before, &:after": {
            content: '""',
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: 16,
            height: 16,
        },
        "&:before": {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        "&:after": {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "none",
        width: 16,
        height: 16,
        margin: 2,
        marginRight: 0,
    },
}));

const emptyReason = {
    id: uid(),
    reason_name: "",
    description: "",
    experience: "",
    keywords: [""],
    price: "",
    areas_served: "",
    availability: "",
    is_hidden: false,
    education: [],
    experience_and_portfolio: [],
};

const Profile = () => {
    const [editMode, setEditMode] = useState(false);
    const inputRef = useRef();

    const location = useLocation();
    const [url, setUrl] = useState(new URL(window.location.href));
    const [userId, setUuserId] = useState(url.searchParams.get("id") || "");

    useEffect(() => {
        setUrl(new URL(window.location.href));
        setUuserId(url.searchParams.get("id") || "");
    }, [location]);

    const timeZones = useMemo(() => {
        return moment.tz.names().map((tzName) => {
            const date = new Date();
            const offset = moment.tz(tzName).utcOffset();
            const sign = offset >= 0 ? "+" : "-";
            const hours = Math.floor(Math.abs(offset) / 60);
            const minutes = Math.abs(offset) % 60;
            const formattedOffset = `${sign}${hours
                .toString()
                .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
            return { name: tzName, offset: formattedOffset };
        });
    }, []);

    const [profile, setProfile] = useState({
        name: "",
        photoURL: "",
        location: "",
        time_zone: "",
        user_description: "",
        who_is_going_to_answer: "human",
        links: [
            {
                name: "",
                url: "",
            },
        ],
        languages: [
            {
                name: "",
                level: "",
            },
        ],
        contactReasons: [{ ...emptyReason, id: uid() }],
    });

    const filteredOfferings = profile.contactReasons.filter(
        (offering) => offering && offering.reason_name.length > 0
    );

    const handleChangeNested = (e, prop, index, subProp) => {
        const newArray = [...profile[prop]];
        newArray[index] = { ...newArray[index], [subProp]: e.target.value };
        setProfile({ ...profile, [prop]: newArray });
    };

    const handleKeywordsChange = (e, reasonIndex, keywordIndex) => {
        const newReasons = [...profile.contactReasons];
        newReasons[reasonIndex].keywords[keywordIndex] = e.target.value;
        setProfile({ ...profile, contactReasons: newReasons });
    };

    const addKeyword = (reasonIndex) => {
        const newReasons = [...profile.contactReasons];
        newReasons[reasonIndex].keywords.push("");
        setProfile({ ...profile, contactReasons: newReasons });
    };

    const deleteKeyword = (reasonIndex, keywordIndex) => {
        const newReasons = [...profile.contactReasons];
        newReasons[reasonIndex].keywords = newReasons[
            reasonIndex
        ].keywords.filter((_, i) => i !== keywordIndex);
        setProfile({ ...profile, contactReasons: newReasons });
    };

    const addContactReason = () => {
        const newReason = { ...emptyReason };
        setProfile({
            ...profile,
            contactReasons: [...profile.contactReasons, newReason],
        });
    };

    const deleteContactReason = (index) => {
        const newReasons = profile.contactReasons.filter((_, i) => i !== index);
        setProfile({ ...profile, contactReasons: newReasons });
    };

    const handleChange = (e, prop, index) => {
        if (index !== undefined) {
            const newArray = [...profile[prop]];
            newArray[index] = { ...newArray[index], ...e };
            setProfile({ ...profile, [prop]: newArray });
        } else {
            setProfile({ ...profile, [prop]: e.target.value });
        }
    };

    const addLink = () => {
        const newLink = { name: "", url: "" };
        setProfile({ ...profile, links: [...profile.links, newLink] });
    };

    const deleteLink = (index) => {
        const newLinks = profile.links.filter((_, i) => i !== index);
        setProfile({ ...profile, links: newLinks });
    };

    const addLanguage = () => {
        const newLanguage = { name: "", level: "" };
        setProfile({
            ...profile,
            languages: [...profile.languages, newLanguage],
        });
    };

    const deleteLanguage = (index) => {
        const newLanguages = profile.languages.filter((_, i) => i !== index);
        setProfile({ ...profile, languages: newLanguages });
    };

    const handleEducationChange = (reasonIndex, index, field, value) => {
        const newEducation = [...profile.contactReasons[reasonIndex].education];
        newEducation[index][field] = value;
        const newReasons = [...profile.contactReasons];
        newReasons[reasonIndex].education = newEducation;
        setProfile({ ...profile, contactReasons: newReasons });
    };

    const handleExperienceChange = (reasonIndex, index, field, value) => {
        const newExperience = [
            ...profile.contactReasons[reasonIndex].experience_and_portfolio,
        ];
        newExperience[index][field] = value;
        const newReasons = [...profile.contactReasons];
        newReasons[reasonIndex].experience_and_portfolio = newExperience;
        setProfile({ ...profile, contactReasons: newReasons });
    };

    const addEducation = (reasonIndex) => {
        const newEducation = [...profile.contactReasons[reasonIndex].education];
        newEducation.push({ name: "", degree: "", graduation_year: "" });
        const newReasons = [...profile.contactReasons];
        newReasons[reasonIndex].education = newEducation;
        setProfile({ ...profile, contactReasons: newReasons });
    };

    const removeEducation = (reasonIndex, index) => {
        const newEducation = [...profile.contactReasons[reasonIndex].education];
        newEducation.splice(index, 1);
        const newReasons = [...profile.contactReasons];
        newReasons[reasonIndex].education = newEducation;
        setProfile({ ...profile, contactReasons: newReasons });
    };

    const addExperience = (reasonIndex) => {
        const newExperience = [
            ...profile.contactReasons[reasonIndex].experience_and_portfolio,
        ];
        newExperience.push({ name: "", degree: "", graduation_year: "" });
        const newReasons = [...profile.contactReasons];
        newReasons[reasonIndex].experience_and_portfolio = newExperience;
        setProfile({ ...profile, contactReasons: newReasons });
    };

    const removeExperience = (reasonIndex, index) => {
        const newExperience = [
            ...profile.contactReasons[reasonIndex].experience_and_portfolio,
        ];
        newExperience.splice(index, 1);
        const newReasons = [...profile.contactReasons];
        newReasons[reasonIndex].experience_and_portfolio = newExperience;
        setProfile({ ...profile, contactReasons: newReasons });
    };

    const [user] = useAuthState(auth);
    const [image, setImage] = useState(null);
    const [progress, setProgress] = useState(0);
    const [currentUser, setCurrentUser] = useState({});

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
            handleUpload(e.target.files[0]);
        }
    };

    const handleUpload = (image) => {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progress);
            },
            (error) => {},
            () => {
                storage
                    .ref("images")
                    .child(image.name)
                    .getDownloadURL()
                    .then((url) => {
                        setProfile({ ...profile, photoURL: url });
                        setProgress(0);
                    });
            }
        );
    };

    useEffect(() => {}, [user]);

    useEffect(() => {
        if (userId) {
            getUserData(userId);
            mixpanel.track("Viewed Profile", { userId });
        } else if (user) {
            getUserData();
            mixpanel.track("Viewed Profile", { userId: user?.uid });
        }
    }, [userId, user]);

    const usersRef = firestore.collection("users");

    const getUserData = async (uid = user?.uid) => {
        const usersQuery = usersRef.where("uid", "==", uid);
        usersQuery
            .get()
            .then((querySnapshot) => {
                if (querySnapshot.empty) {
                    usersRef
                        .add({
                            ...profile,
                            uid: user.uid,
                        })
                        .then((docRef) => {
                            setProfile({ ...profile, uid: user.uid });
                        });
                } else {
                    querySnapshot.forEach((doc) => {
                        setProfile(doc.data());
                    });
                }
            })
            .catch((error) => {});
    };

    const saveProfileToSearch = async () => {
        const offerings = [...profile.contactReasons];
        console.log(offerings);
        const newOfferings = offerings.map((offering) => {
            const profileWithoutOfferings = { ...profile };
            delete profileWithoutOfferings.contactReasons;
            const newOffer = {
                messageLinkCallToActionButton: "/chat?userId=" + profile.uid,
                ...offering,
                ...profileWithoutOfferings,
            };
            newOffer.id = offering.id || uid();
            const document = {
                id: newOffer.id,
                text: JSON.stringify(newOffer),
                metadata: newOffer,
            };
            return document;
        });

        const body = {
            documents: [...newOfferings],
        };

        try {
            const response = await axios.post(API_URL + "/upsert", body, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + API_KEY,
                },
            });
            alert("Profile saved successfully");
            mixpanel.track("Saved Profile", { userId: user?.uid });
        } catch (error) {
            alert("Error saving profile");
        }
    };
    const saveProfile = () => {
        const newProfile = { ...profile };
        const usersQuery = usersRef.where("uid", "==", user.uid);
        usersQuery
            .get()
            .then((querySnapshot) => {
                if (querySnapshot.empty) {
                    usersRef
                        .add({ ...newProfile, uid: user.uid })
                        .then(async (doc) => {
                            await getUserData();
                            saveProfileToSearch();
                            setEditMode(false);
                        });
                } else {
                    querySnapshot.forEach((doc) => {
                        doc.ref
                            .update({ ...newProfile, uid: user.uid })
                            .then(async (doc) => {
                                await getUserData();
                                saveProfileToSearch();
                                setEditMode(false);
                            })
                            .catch((error) => {});
                    });
                }
            })
            .catch((error) => {});
    };

    return (
        <div>
            <div>
                {userId && (
                    <div
                        style={{
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
                        }}
                    >
                        <div
                            style={{
                                margin: "auto",
                                maxWidth: 668,
                                width: "100%",
                            }}
                        >
                            <ChatHeader
                                isOnProfile
                                chatId={""}
                                userId={user?.uid || userId}
                            />
                        </div>
                    </div>
                )}
            </div>

            <div style={{ width: "100%", maxWidth: 668, margin: "auto" }}>
                {profile && profile?.uid && profile?.uid.length > 0 && (
                    <div>
                        {editMode ? (
                            <div>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <div></div>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    wrap="nowrap"
                                    style={{ width: "100%" }}
                                >
                                    <Box>
                                        <Box
                                            position="relative"
                                            display="inline-block"
                                            sx={{ zIndex: 3 }}
                                        >
                                            <Avatar
                                                src={profile.photoURL}
                                                alt="Profile picture"
                                                sx={{ width: 100, height: 100 }}
                                            />
                                            <Box
                                                position="absolute"
                                                bottom={0}
                                                right={0}
                                            >
                                                <IconButton
                                                    color="primary"
                                                    onClick={() =>
                                                        inputRef.current.click()
                                                    }
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        <input
                                            type="file"
                                            ref={inputRef}
                                            onChange={handleFileChange}
                                            style={{
                                                position: "absolute",
                                                width: 0.1,
                                                height: 0.1,
                                                opacity: 0,
                                                overflow: "hidden",
                                                zIndex: -1,
                                            }}
                                        />
                                        <Box sx={{ mt: 2 }}>
                                            {progress > 0 && (
                                                <LinearProgress
                                                    value={progress}
                                                    max="100"
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                    <div>
                                        <Typography
                                            component="div"
                                            sx={{ ml: 2 }}
                                        >
                                            Full Name / Company Name / User Name
                                            <br />
                                            {editMode ? (
                                                <TextField
                                                    placeholder="e.g. John Doe"
                                                    value={profile.name}
                                                    sx={{
                                                        width: "100%",
                                                        maxWidth: "300px",
                                                    }}
                                                    onChange={(e) =>
                                                        handleChange(e, "name")
                                                    }
                                                />
                                            ) : (
                                                profile.name
                                            )}
                                        </Typography>
                                        <br />
                                    </div>
                                    <Grid
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Button
                                            onClick={saveProfile}
                                            color="primary"
                                            aria-label="edit profile"
                                            variant="contained"
                                            size="small"
                                            sx={{ ml: 2 }}
                                        >
                                            Save{" "}
                                        </Button>
                                        <Button
                                            sx={{ ml: 2 }}
                                            onClick={() => {
                                                setEditMode(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Typography component="div">
                                    Description{" "}
                                    {editMode ? (
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={2}
                                            placeholder="e.g. Experienced web developer and graphic designer."
                                            value={profile.user_description}
                                            onChange={(e) =>
                                                handleChange(
                                                    e,
                                                    "user_description"
                                                )
                                            }
                                        />
                                    ) : (
                                        profile.name
                                    )}
                                </Typography>
                                <br />
                                <Typography component="div">Links</Typography>
                                <div>
                                    {profile?.links.map((link, index) => (
                                        <li
                                            key={index}
                                            style={{ marginTop: "10px" }}
                                        >
                                            {editMode ? (
                                                <>
                                                    <TextField
                                                        value={link.name}
                                                        placeholder="e.g. LinkedIn"
                                                        onChange={(e) =>
                                                            handleChange(
                                                                {
                                                                    name: e
                                                                        .target
                                                                        .value,
                                                                },
                                                                "links",
                                                                index
                                                            )
                                                        }
                                                    />
                                                    <TextField
                                                        value={link.url}
                                                        placeholder="e.g. https://www.linkedin.com/in/johndoe"
                                                        onChange={(e) =>
                                                            handleChange(
                                                                {
                                                                    url: e
                                                                        .target
                                                                        .value,
                                                                },
                                                                "links",
                                                                index
                                                            )
                                                        }
                                                    />
                                                    <IconButton
                                                        onClick={() =>
                                                            deleteLink(index)
                                                        }
                                                        aria-label="delete link"
                                                        sx={{ mt: "5px" }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                            ) : (
                                                <Link
                                                    href={link.url}
                                                    target="_blank"
                                                    rel="noopener"
                                                >
                                                    {link.name}
                                                </Link>
                                            )}
                                        </li>
                                    ))}
                                    {editMode && (
                                        <li>
                                            <Button
                                                sx={{ mt: 1 }}
                                                onClick={addLink}
                                                color="primary"
                                                aria-label="add link"
                                                variant="outlined"
                                            >
                                                Add Link <AddIcon />
                                            </Button>
                                        </li>
                                    )}
                                </div>
                                <br />
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">
                                        who is going to answer?
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={profile.who_is_going_to_answer}
                                        onChange={(e) =>
                                            handleChange(
                                                e,
                                                "who_is_going_to_answer"
                                            )
                                        }
                                    >
                                        <FormControlLabel
                                            value="human"
                                            control={<Radio />}
                                            label="Human"
                                        />
                                        <FormControlLabel
                                            value="bot"
                                            control={<Radio />}
                                            label="Chat bot"
                                        />
                                        <FormControlLabel
                                            value="both"
                                            control={<Radio />}
                                            label="Both"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {(profile.who_is_going_to_answer === "bot" ||
                                    profile.who_is_going_to_answer ===
                                        "both") && (
                                    <div>
                                        <Typography
                                            component="div"
                                            variant="caption"
                                        >
                                            look at our{" "}
                                            <Link
                                                href="/chatbot"
                                                target="_blank"
                                            >
                                                documantions and chat bot
                                                examples
                                            </Link>{" "}
                                            to create and deploy your own chat
                                            bot
                                        </Typography>
                                        <Typography component="div">
                                            Chat Bot Manifest.json URL
                                        </Typography>
                                        <TextField
                                            title="chat bot manifest.json url"
                                            placeholder="e.g. https://your-chat-bot-url.com/manifest.json"
                                            value={profile.chatbot_manifest_url}
                                            onChange={(e) =>
                                                handleChange(
                                                    e,
                                                    "chatbot_manifest_url"
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                <br />
                                <br />

                                <Typography component="div">
                                    Location
                                    <br />
                                    {editMode ? (
                                        <TextField
                                            placeholder="e.g. New York, NY, USA"
                                            value={profile.location}
                                            onChange={(e) =>
                                                handleChange(e, "location")
                                            }
                                        />
                                    ) : (
                                        profile.location
                                    )}
                                </Typography>
                                <br />
                                <Typography component="div">
                                    Time Zone
                                    <br />
                                    {editMode ? (
                                        <>
                                            <FormControl>
                                                <InputLabel>
                                                    Time Zone
                                                </InputLabel>
                                                <Select
                                                    sx={{
                                                        width: 200,
                                                        maxWidth: "100%",
                                                    }}
                                                    value={profile?.time_zone}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            e,
                                                            "time_zone"
                                                        )
                                                    }
                                                >
                                                    {timeZones.map((tz) => (
                                                        <MenuItem
                                                            key={`${tz.name} ${tz.offset}`}
                                                            value={`${tz.name} ${tz.offset}`}
                                                        >
                                                            {tz.name} (UTC
                                                            {tz.offset})
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </>
                                    ) : (
                                        profile?.time_zone
                                    )}
                                </Typography>
                                <br />
                                <Typography component="div">
                                    Languages
                                </Typography>
                                <div>
                                    {profile.languages.map(
                                        (language, index) => (
                                            <li
                                                key={index}
                                                style={{ marginTop: "10px" }}
                                            >
                                                {editMode ? (
                                                    <>
                                                        <TextField
                                                            value={
                                                                language.name
                                                            }
                                                            placeholder="e.g. English"
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    {
                                                                        name: e
                                                                            .target
                                                                            .value,
                                                                    },
                                                                    "languages",
                                                                    index
                                                                )
                                                            }
                                                        />
                                                        <FormControl>
                                                            <InputLabel>
                                                                Level
                                                            </InputLabel>
                                                            <Select
                                                                placeholder="e.g. Proficient"
                                                                sx={{
                                                                    width: 200,
                                                                    maxWidth:
                                                                        "100%",
                                                                }}
                                                                value={
                                                                    language.level
                                                                }
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        {
                                                                            level: e
                                                                                .target
                                                                                .value,
                                                                        },
                                                                        "languages",
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                {[
                                                                    "Beginner",
                                                                    "Elementary",
                                                                    "Intermediate",
                                                                    "Advanced",
                                                                    "Proficient",
                                                                ].map(
                                                                    (level) => (
                                                                        <MenuItem
                                                                            key={
                                                                                level
                                                                            }
                                                                            value={
                                                                                level
                                                                            }
                                                                        >
                                                                            {
                                                                                level
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                        <IconButton
                                                            onClick={() =>
                                                                deleteLanguage(
                                                                    index
                                                                )
                                                            }
                                                            aria-label="delete language"
                                                            sx={{ mt: "5px" }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </>
                                                ) : (
                                                    `${language.name} - ${language.level}`
                                                )}
                                            </li>
                                        )
                                    )}
                                    {editMode && (
                                        <li>
                                            <Button
                                                sx={{ mt: 1 }}
                                                onClick={addLanguage}
                                                color="primary"
                                                aria-label="add language"
                                                variant="outlined"
                                            >
                                                Add Language <AddIcon />
                                            </Button>
                                        </li>
                                    )}
                                </div>
                                <br />
                                <Typography variant="subtitle1">
                                    {" "}
                                    Contact me about these topics{" "}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    component="div"
                                    sx={{
                                        lineHeight: "14px",
                                    }}
                                >
                                    Here are the topics this user wants to be
                                    found and contacted about. An example could
                                    be work offerings, local services,
                                    interests, activities, consulting, providing
                                    information, etc.
                                </Typography>

                                <div style={{ marginTop: "10px" }}>
                                    {profile.contactReasons.map(
                                        (reason, reasonIndex) => (
                                            <Accordion
                                                key={reasonIndex}
                                                defaultExpanded={true}
                                            >
                                                <AccordionSummary
                                                    aria-controls="panel1d-content"
                                                    id="panel1d-header"
                                                    sx={{ py: 0 }}
                                                    expandIcon={<ExpandMore />}
                                                >
                                                    <Typography
                                                        variant="subtitle1"
                                                        sx={{ p: 0, my: 0 }}
                                                    >
                                                        {reason.reason_name
                                                            .length === 0 ? (
                                                            <>
                                                                Reason{" "}
                                                                {reasonIndex +
                                                                    1}{" "}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {
                                                                    reason.reason_name
                                                                }{" "}
                                                            </>
                                                        )}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    sx={{ pt: 0 }}
                                                >
                                                    <div
                                                        sx={{ mt: 2, p: 2 }}
                                                        key={reasonIndex}
                                                    >
                                                        {editMode ? (
                                                            <>
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                    alignItems="flex-start"
                                                                >
                                                                    <div>
                                                                        <Typography component="div">
                                                                            Reason
                                                                            Name
                                                                        </Typography>
                                                                        <TextField
                                                                            placeholder="e.g. web development"
                                                                            value={
                                                                                reason.reason_name
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                handleChangeNested(
                                                                                    e,
                                                                                    "contactReasons",
                                                                                    reasonIndex,
                                                                                    "reason_name"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <Grid
                                                                            container
                                                                            direction="column"
                                                                            justifyContent="flex-end"
                                                                            alignItems="flex-end"
                                                                        >
                                                                            <Button
                                                                                onClick={() =>
                                                                                    deleteContactReason(
                                                                                        reasonIndex
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    mt: "5px",
                                                                                }}
                                                                                variant="outlined"
                                                                                aria-label="delete Offering"
                                                                            >
                                                                                Delete
                                                                                Offering{" "}
                                                                                <DeleteIcon />
                                                                            </Button>
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    alignItems:
                                                                                        "center",
                                                                                }}
                                                                            >
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Android12Switch
                                                                                            onChange={(
                                                                                                e
                                                                                            ) => {
                                                                                                handleChangeNested(
                                                                                                    e,
                                                                                                    "contactReasons",
                                                                                                    reasonIndex,
                                                                                                    "is_hidden"
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    label="Hide from profile"
                                                                                />
                                                                                <Tooltip
                                                                                    title="Hide this offering from other users who visit your profile but still get inquiries about it when users search for this reason."
                                                                                    arrow
                                                                                >
                                                                                    <InfoIcon />
                                                                                </Tooltip>
                                                                            </div>
                                                                        </Grid>
                                                                    </div>
                                                                </Grid>
                                                                <br />
                                                                <Typography component="div">
                                                                    Description
                                                                </Typography>
                                                                <TextField
                                                                    placeholder="e.g. Experienced web developer and graphic designer passionate about helping businesses grow online."
                                                                    value={
                                                                        reason.description
                                                                    }
                                                                    multiline
                                                                    fullWidth
                                                                    rows={2}
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeNested(
                                                                            e,
                                                                            "contactReasons",
                                                                            reasonIndex,
                                                                            "description"
                                                                        )
                                                                    }
                                                                />
                                                                <br />
                                                                <br />
                                                                <Typography component="div">
                                                                    Keywords and
                                                                    Skills
                                                                </Typography>
                                                                <div>
                                                                    {reason?.keywords &&
                                                                        reason.keywords.map(
                                                                            (
                                                                                keyword,
                                                                                keywordIndex
                                                                            ) => (
                                                                                <li
                                                                                    key={
                                                                                        keywordIndex
                                                                                    }
                                                                                    style={{
                                                                                        marginTop:
                                                                                            "10px",
                                                                                    }}
                                                                                >
                                                                                    <TextField
                                                                                        value={
                                                                                            keyword
                                                                                        }
                                                                                        placeholder="e.g. Html"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleKeywordsChange(
                                                                                                e,
                                                                                                reasonIndex,
                                                                                                keywordIndex
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <IconButton
                                                                                        onClick={() =>
                                                                                            deleteKeyword(
                                                                                                reasonIndex,
                                                                                                keywordIndex
                                                                                            )
                                                                                        }
                                                                                        sx={{
                                                                                            mt: "5px",
                                                                                        }}
                                                                                        aria-label="delete keyword"
                                                                                    >
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                </li>
                                                                            )
                                                                        )}
                                                                    <li>
                                                                        <Button
                                                                            onClick={() =>
                                                                                addKeyword(
                                                                                    reasonIndex
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                mt: 1,
                                                                            }}
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            aria-label="add keyword"
                                                                        >
                                                                            Add
                                                                            Keyword
                                                                            /
                                                                            Skill{" "}
                                                                            <AddIcon />
                                                                        </Button>
                                                                    </li>
                                                                </div>

                                                                <br />
                                                                <Typography component="div">
                                                                    Price
                                                                </Typography>
                                                                <TextField
                                                                    placeholder="e.g. 50$/hour"
                                                                    value={
                                                                        reason.price
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeNested(
                                                                            e,
                                                                            "contactReasons",
                                                                            reasonIndex,
                                                                            "price"
                                                                        )
                                                                    }
                                                                />
                                                                <br />
                                                                <br />
                                                                <Typography component="div">
                                                                    Areas served
                                                                </Typography>
                                                                <TextField
                                                                    placeholder="e.g. Worldwide"
                                                                    value={
                                                                        reason.areas_served
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeNested(
                                                                            e,
                                                                            "contactReasons",
                                                                            reasonIndex,
                                                                            "areas_served"
                                                                        )
                                                                    }
                                                                />
                                                                <br />
                                                                <br />
                                                                <Typography component="div">
                                                                    Availability
                                                                    / Open Hours
                                                                </Typography>

                                                                <TextField
                                                                    placeholder="e.g. 20 hours a week / 
                                                    Monday - Friday: 8:00 AM - 9:00 PM
                                                    Saturday: 9:00 AM - 8:00 PM
                                                    Sunday: 10:00 AM - 6:00 PM "
                                                                    multiline
                                                                    fullWidth
                                                                    rows={2}
                                                                    value={
                                                                        reason.availability
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeNested(
                                                                            e,
                                                                            "contactReasons",
                                                                            reasonIndex,
                                                                            "availability"
                                                                        )
                                                                    }
                                                                />

                                                                <br />
                                                                <br />

                                                                <Typography component="div">
                                                                    Experience
                                                                </Typography>
                                                                <TextField
                                                                    value={
                                                                        reason.experience
                                                                    }
                                                                    placeholder="e.g. 5 years"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeNested(
                                                                            e,
                                                                            "contactReasons",
                                                                            reasonIndex,
                                                                            "experience"
                                                                        )
                                                                    }
                                                                />

                                                                <br />
                                                                <br />
                                                                <Typography component="div">
                                                                    Education
                                                                </Typography>

                                                                {profile?.contactReasons[
                                                                    reasonIndex
                                                                ].education?.map(
                                                                    (
                                                                        edu,
                                                                        index
                                                                    ) => (
                                                                        <Box
                                                                            key={
                                                                                index
                                                                            }
                                                                            sx={{
                                                                                mb: 1,
                                                                            }}
                                                                        >
                                                                            <TextField
                                                                                placeholder="e.g. University of California, Berkeley"
                                                                                label="Institution"
                                                                                value={
                                                                                    edu.name
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    handleEducationChange(
                                                                                        reasonIndex,
                                                                                        index,
                                                                                        "name",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                            />
                                                                            <TextField
                                                                                placeholder="e.g. Bachelor of Science in Computer Science"
                                                                                label="Degree"
                                                                                value={
                                                                                    edu.degree
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    handleEducationChange(
                                                                                        reasonIndex,
                                                                                        index,
                                                                                        "degree",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                            />
                                                                            <TextField
                                                                                placeholder="e.g. 2018"
                                                                                label="Graduation Year"
                                                                                value={
                                                                                    edu.graduation_year
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    handleEducationChange(
                                                                                        reasonIndex,
                                                                                        index,
                                                                                        "graduation_year",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                            />
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    removeEducation(
                                                                                        reasonIndex,
                                                                                        index
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    mt: "5px",
                                                                                }}
                                                                            >
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Box>
                                                                    )
                                                                )}
                                                                <Box mt={1}>
                                                                    <Button
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={() =>
                                                                            addEducation(
                                                                                reasonIndex
                                                                            )
                                                                        }
                                                                    >
                                                                        <AddIcon />
                                                                        Add
                                                                        Education
                                                                    </Button>
                                                                </Box>
                                                                <br />
                                                                <Typography component="div">
                                                                    Experience
                                                                    and
                                                                    Portfolio
                                                                </Typography>

                                                                {profile?.contactReasons[
                                                                    reasonIndex
                                                                ]?.experience_and_portfolio.map(
                                                                    (
                                                                        exp,
                                                                        index
                                                                    ) => (
                                                                        <Box
                                                                            key={
                                                                                index
                                                                            }
                                                                            sx={{
                                                                                mb: 1,
                                                                            }}
                                                                        >
                                                                            <TextField
                                                                                placeholder="e.g. Web Developer"
                                                                                label="Position"
                                                                                value={
                                                                                    exp.name
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    handleExperienceChange(
                                                                                        reasonIndex,
                                                                                        index,
                                                                                        "name",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                            />
                                                                            <TextField
                                                                                placeholder="e.g. Google"
                                                                                label="Company"
                                                                                value={
                                                                                    exp.company
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    handleExperienceChange(
                                                                                        reasonIndex,
                                                                                        index,
                                                                                        "company",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                            />
                                                                            <TextField
                                                                                placeholder="e.g. 2018-01-01"
                                                                                label="Start Date"
                                                                                value={
                                                                                    exp.start_date
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    handleExperienceChange(
                                                                                        reasonIndex,
                                                                                        index,
                                                                                        "start_date",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                            />
                                                                            <TextField
                                                                                placeholder="e.g. 2019-01-01"
                                                                                label="End Date"
                                                                                value={
                                                                                    exp.end_date
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    handleExperienceChange(
                                                                                        reasonIndex,
                                                                                        index,
                                                                                        "end_date",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                            />
                                                                            <TextField
                                                                                placeholder="e.g. Collaborated with cross-functional teams to develop, test, and deploy responsive web applications using HTML5, CSS3, JavaScript, and modern frameworks (e.g., React, Angular)."
                                                                                label="Description"
                                                                                sx={{
                                                                                    width: "calc(100% - 48px)",
                                                                                }}
                                                                                fullWidth
                                                                                value={
                                                                                    exp.description
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    handleExperienceChange(
                                                                                        reasonIndex,
                                                                                        index,
                                                                                        "description",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                            />
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    removeExperience(
                                                                                        reasonIndex,
                                                                                        index
                                                                                    )
                                                                                }
                                                                                sx={{
                                                                                    mt: "5px",
                                                                                }}
                                                                            >
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Box>
                                                                    )
                                                                )}
                                                                <Box mt={1}>
                                                                    <Button
                                                                        variant="outlined"
                                                                        size="small"
                                                                        onClick={() =>
                                                                            addExperience(
                                                                                reasonIndex
                                                                            )
                                                                        }
                                                                    >
                                                                        <AddIcon />
                                                                        Add
                                                                        Experience
                                                                    </Button>
                                                                </Box>
                                                            </>
                                                        ) : (
                                                            reason.reason_name
                                                        )}
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    )}
                                    {editMode && (
                                        <li>
                                            <Button
                                                sx={{ mt: 1 }}
                                                variant="outlined"
                                                onClick={addContactReason}
                                                color="primary"
                                                aria-label="Add Offerings"
                                            >
                                                Add Contact Topic
                                                <AddIcon />
                                            </Button>
                                        </li>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div>
                                {user?.uid || userId ? (
                                    <div style={{ width: "100%" }}>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-end"
                                            alignItems="flex-start"
                                            wrap="nowrap"
                                            sx={{
                                                width: "100%",
                                                marginTop: "16px",
                                            }}
                                        >
                                            <Avatar
                                                alt="Google Photo/Initial"
                                                src={profile.photoURL}
                                                sx={{ width: 100, height: 100 }}
                                            />
                                            <Box sx={{ ml: 4 }}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="flex-end"
                                                    alignItems="center"
                                                    sx={{ width: "100%" }}
                                                >
                                                    <Typography variant="h4">
                                                        {profile.name ||
                                                            "name not added"}

                                                        {/* {(profile.who_is_going_to_answer ===
                                                                                "both" ||
                                                                                profile.who_is_going_to_answer ===
                                                                                    "human") && (
                                                                                <SupportAgentIcon
                                                                                    fontSize="16px"
                                                                                    sx={{
                                                                                        ml: 0.5,
                                                                                    }}
                                                                                />
                                                                            )} */}
                                                        {(profile.who_is_going_to_answer ===
                                                            "both" ||
                                                            profile.who_is_going_to_answer ===
                                                                "bot") && (
                                                            <Tooltip
                                                                title={
                                                                    profile.who_is_going_to_answer ===
                                                                    "both"
                                                                        ? "Answered by bot and human"
                                                                        : "Answered by bot"
                                                                }
                                                            >
                                                                <SmartToyIcon
                                                                    fontSize="16px"
                                                                    sx={{
                                                                        mx: 1,
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </Typography>
                                                    {!userId ? (
                                                        <Button
                                                            sx={{ ml: 2 }}
                                                            variant="contained"
                                                            size="small"
                                                            color="primary"
                                                            onClick={() => {
                                                                setEditMode(
                                                                    !editMode
                                                                );
                                                            }}
                                                        >
                                                            {editMode
                                                                ? "save"
                                                                : "edit"}
                                                        </Button>
                                                    ) : (
                                                        <>
                                                            <Button
                                                                sx={{ ml: 2 }}
                                                                variant="contained"
                                                                size="small"
                                                                color="primary"
                                                                href={`/chat?userId=${profile.uid}`}
                                                            >
                                                                {"message"}
                                                            </Button>
                                                        </>
                                                    )}
                                                </Grid>
                                                <Typography
                                                    sx={{ mt: 1, mb: 2, p: 0 }}
                                                >
                                                    {profile?.user_description}
                                                </Typography>
                                                {profile.links.map(
                                                    (link, index) => (
                                                        <span>
                                                            <SocialLink
                                                                key={index}
                                                                name={link.name}
                                                                url={link.url}
                                                            />
                                                            {index ===
                                                            profile.links
                                                                .length -
                                                                1
                                                                ? ""
                                                                : " | "}
                                                        </span>
                                                    )
                                                )}
                                            </Box>
                                        </Grid>
                                        <Typography
                                            sx={{ p: 0, mt: 3, mb: 1, pt: 2 }}
                                            variant="caption"
                                        >
                                            {profile.location && (
                                                <>
                                                    <b>Location</b>{" "}
                                                    {profile.location} |
                                                </>
                                            )}
                                            {profile?.time_zone && (
                                                <>
                                                    <b> local time</b>{" "}
                                                    {profile?.time_zone
                                                        ?.length > 0 &&
                                                        getTimeAndOffset(
                                                            profile?.time_zone
                                                        )}{" "}
                                                </>
                                            )}
                                            {profile.languages &&
                                                profile?.languages[0]?.name && (
                                                    <>
                                                        |<b> Languages</b>{" "}
                                                        {profile.languages.map(
                                                            (
                                                                language,
                                                                index
                                                            ) => (
                                                                <span key="index">
                                                                    {
                                                                        language?.name
                                                                    }{" "}
                                                                    {
                                                                        language.level
                                                                    }
                                                                </span>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                        </Typography>
                                        <Divider sx={{ mt: 1, mb: 2 }} />
                                        <Box mt={2}>
                                            <Typography variant="h6">
                                                Contact me about these topics
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                component="div"
                                                sx={{
                                                    lineHeight: "14px",
                                                }}
                                            >
                                                Here are the topics this user
                                                wants to be found and contacted
                                                about. An example could be work
                                                offerings, local services,
                                                interests, activities,
                                                consulting, providing
                                                information, etc.
                                            </Typography>
                                            <br />
                                            {filteredOfferings.length === 0 && (
                                                <Typography
                                                    variant="body1"
                                                    component="div"
                                                    sx={{
                                                        alignContent: "center",
                                                        textAlign: "center",
                                                        mt: 4,
                                                    }}
                                                >
                                                    No Topics to show.
                                                </Typography>
                                            )}

                                            {profile.contactReasons.map(
                                                (reason, index) => (
                                                    <div key={index}>
                                                        {reason.reason_name
                                                            .length > 0 &&
                                                            !reason.is_hidden && (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        marginBottom:
                                                                            "16px",
                                                                    }}
                                                                >
                                                                    <Accordion
                                                                        key={
                                                                            index
                                                                        }
                                                                        defaultExpanded={
                                                                            index ===
                                                                            0
                                                                        }
                                                                        sx={{
                                                                            mb: 2,
                                                                        }}
                                                                    >
                                                                        <AccordionSummary
                                                                            aria-controls="panel1d-content"
                                                                            id="panel1d-header"
                                                                            sx={{
                                                                                py: 0,
                                                                            }}
                                                                            expandIcon={
                                                                                <ExpandMore />
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle1"
                                                                                sx={{
                                                                                    p: 0,
                                                                                    my: 0,
                                                                                }}
                                                                            >
                                                                                {reason
                                                                                    .reason_name
                                                                                    .length ===
                                                                                0 ? (
                                                                                    <>
                                                                                        Reason{" "}
                                                                                        {index +
                                                                                            1}{" "}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {
                                                                                            reason.reason_name
                                                                                        }{" "}
                                                                                    </>
                                                                                )}
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails
                                                                            sx={{
                                                                                pt: 0,
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    pl: 0,
                                                                                    pt: 0,
                                                                                    mt: 0,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    reason.description
                                                                                }
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    pl: 0,
                                                                                    py: 1,
                                                                                }}
                                                                            >
                                                                                {reason.price && (
                                                                                    <>
                                                                                        <b>
                                                                                            {" "}
                                                                                            Price
                                                                                        </b>{" "}
                                                                                        {
                                                                                            reason.price
                                                                                        }
                                                                                    </>
                                                                                )}
                                                                                {reason.experience && (
                                                                                    <>
                                                                                        |{" "}
                                                                                        <b>
                                                                                            Experience
                                                                                        </b>{" "}
                                                                                        {
                                                                                            reason.experience
                                                                                        }{" "}
                                                                                    </>
                                                                                )}

                                                                                {reason.areas_served && (
                                                                                    <>
                                                                                        |{" "}
                                                                                        <b>
                                                                                            Areas
                                                                                            Served
                                                                                        </b>{" "}
                                                                                        {
                                                                                            reason.areas_served
                                                                                        }{" "}
                                                                                    </>
                                                                                )}

                                                                                {reason.availability && (
                                                                                    <>
                                                                                        |{" "}
                                                                                        <b>
                                                                                            Availability
                                                                                        </b>{" "}
                                                                                        {
                                                                                            reason.availability
                                                                                        }{" "}
                                                                                    </>
                                                                                )}
                                                                            </Typography>
                                                                            <Box
                                                                                mt={
                                                                                    2
                                                                                }
                                                                            >
                                                                                {reason &&
                                                                                    reason?.keywords &&
                                                                                    reason
                                                                                        ?.keywords
                                                                                        .length >
                                                                                        0 &&
                                                                                    reason
                                                                                        ?.keywords[0]
                                                                                        ?.length >
                                                                                        0 && (
                                                                                        <>
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    mb: 1,
                                                                                                    mt: 2,
                                                                                                }}
                                                                                                variant="subtitle1"
                                                                                            >
                                                                                                Keywords
                                                                                                and
                                                                                                Skills
                                                                                            </Typography>
                                                                                            <Grid
                                                                                                container
                                                                                                spacing={
                                                                                                    1
                                                                                                }
                                                                                                sx={{
                                                                                                    mb: 4,
                                                                                                }}
                                                                                            >
                                                                                                {reason?.keywords?.map(
                                                                                                    (
                                                                                                        keyword,
                                                                                                        index
                                                                                                    ) => (
                                                                                                        <Grid
                                                                                                            key={
                                                                                                                index
                                                                                                            }
                                                                                                            item
                                                                                                        >
                                                                                                            {keyword &&
                                                                                                            keyword.length >
                                                                                                                0 ? (
                                                                                                                <Chip
                                                                                                                    label={
                                                                                                                        keyword
                                                                                                                    }
                                                                                                                />
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}
                                                                                                        </Grid>
                                                                                                    )
                                                                                                )}
                                                                                            </Grid>
                                                                                        </>
                                                                                    )}
                                                                            </Box>
                                                                            {reason.education &&
                                                                                reason
                                                                                    .education[0]
                                                                                    ?.name
                                                                                    .length >
                                                                                    0 && (
                                                                                    <Box
                                                                                        mt={
                                                                                            0
                                                                                        }
                                                                                    >
                                                                                        <Typography variant="subtitle1">
                                                                                            Education
                                                                                        </Typography>
                                                                                        <div>
                                                                                            {reason.education.map(
                                                                                                (
                                                                                                    edu,
                                                                                                    index
                                                                                                ) => (
                                                                                                    <div
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                        style={{
                                                                                                            marginTop:
                                                                                                                "10px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Typography
                                                                                                            sx={{
                                                                                                                py: 0,
                                                                                                            }}
                                                                                                        >
                                                                                                            {`Graduated: ${edu.graduation_year}`}
                                                                                                        </Typography>
                                                                                                        <Typography
                                                                                                            sx={{
                                                                                                                py: 0,
                                                                                                            }}
                                                                                                        >
                                                                                                            {`${edu?.degree}, ${edu?.name}`}
                                                                                                        </Typography>
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                        </div>
                                                                                    </Box>
                                                                                )}{" "}
                                                                            {reason.experience_and_portfolio &&
                                                                                reason
                                                                                    .experience_and_portfolio[0]
                                                                                    ?.name
                                                                                    .length >
                                                                                    0 && (
                                                                                    <Box
                                                                                        mt={
                                                                                            4
                                                                                        }
                                                                                    >
                                                                                        <Typography variant="subtitle1">
                                                                                            Experience
                                                                                            and
                                                                                            Portfolio
                                                                                        </Typography>
                                                                                        <div>
                                                                                            {reason.experience_and_portfolio.map(
                                                                                                (
                                                                                                    exp,
                                                                                                    index
                                                                                                ) => (
                                                                                                    <Grid
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                        container
                                                                                                        direction="row"
                                                                                                        justify="flex-start"
                                                                                                        alignItems="flex-start"
                                                                                                        sx={{
                                                                                                            marginTop:
                                                                                                                "10px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <Typography
                                                                                                            sx={{
                                                                                                                py: 0,
                                                                                                            }}
                                                                                                        >{`${
                                                                                                            exp.start_date
                                                                                                        } - ${
                                                                                                            exp.end_date ||
                                                                                                            "Present"
                                                                                                        }`}</Typography>
                                                                                                        <div>
                                                                                                            <Typography
                                                                                                                sx={{
                                                                                                                    py: 0,
                                                                                                                }}
                                                                                                            >
                                                                                                                {`${exp.name}, ${exp.company}`}
                                                                                                            </Typography>
                                                                                                            <Typography
                                                                                                                variant="body2"
                                                                                                                sx={{
                                                                                                                    py: 0,
                                                                                                                }}
                                                                                                            >
                                                                                                                {
                                                                                                                    exp.description
                                                                                                                }
                                                                                                            </Typography>
                                                                                                        </div>
                                                                                                    </Grid>
                                                                                                )
                                                                                            )}
                                                                                        </div>
                                                                                    </Box>
                                                                                )}
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </div>
                                                            )}
                                                    </div>
                                                )
                                            )}
                                        </Box>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {(user?.uid || userId) &&
                    !(profile && profile?.uid && profile?.uid.length > 0) && (
                        <LinearProgress />
                    )}
                <div>
                    {!user?.uid && !userId && (
                        <Grid
                            container
                            direction={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{ mt: 3 }}
                        >
                            <SignIn text={"Sign In to continue"} />
                        </Grid>
                    )}
                </div>
            </div>
        </div>
    );
};

const SocialLink = ({ name, url, isEditing }) => {
    return (
        <Link href={url} target="_blank" rel="noopener">
            {name}
        </Link>
    );
};

const Terms = () => {
    const navigate = useNavigate();
    return (
        <div
            style={{
                maxWidth: "668px",
                margin: "0 auto",
                padding: "0 20px",
            }}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                wrap="nowrap"
            >
                <IconButton
                    onClick={() => {
                        goBackOrHome();
                    }}
                    sx={{ margin: "5px" }}
                >
                    <ArrowBackIos />
                </IconButton>
                <Typography
                    variant="body1"
                    sx={{
                        p: 0,
                        m: 0,
                        fontWeight: 700,
                        py: 1,
                        width: "100%",
                    }}
                >
                    Uniget Terms of Service
                </Typography>
            </Grid>

            <section>
                <h3>1. Acceptance of Terms</h3>
                <p>
                    By using the UniGet website, you agree to be bound by these
                    Terms of Service, all applicable laws and regulations, and
                    agree that you are responsible for compliance with any
                    applicable local laws. If you do not agree with any of these
                    terms, you are prohibited from using or accessing this site.
                </p>
            </section>

            <section>
                <h3>2. Registration and User Information</h3>
                <p>
                    To use certain features of UniGet, you may be required to
                    create an account and provide accurate and complete
                    information. You are responsible for maintaining the
                    confidentiality of your account and password, and for any
                    activities that occur under your account.
                </p>
            </section>

            <section>
                <h3>3. User Conduct</h3>
                <p>
                    As a user of UniGet, you agree to not use the service for
                    any unlawful purpose or in a way that violates these Terms
                    of Service. You also agree to not use the service to harass,
                    threaten, or defame others. UniGet reserves the right to
                    terminate or suspend your access to the service for any
                    violation of these terms.
                </p>
            </section>

            <section>
                <h3>4. Intellectual Property Rights</h3>
                <p>
                    All content on the UniGet website, including text, graphics,
                    logos, and software, is the property of UniGet or its
                    content suppliers and is protected by international
                    copyright laws. You agree not to reproduce, duplicate, copy,
                    sell, resell or exploit any portion of the service without
                    express written permission from UniGet.
                </p>
            </section>

            <section>
                <h3>5. Limitations</h3>
                <p>
                    In no event shall UniGet or its suppliers be liable for any
                    damages (including, without limitation, damages for loss of
                    data or profit, or due to business interruption) arising out
                    of the use or inability to use the UniGet website, even if
                    UniGet or a UniGet authorized representative has been
                    notified orally or in writing of the possibility of such
                    damage.
                </p>
            </section>

            <section>
                <h3>6. Revisions and Errata</h3>
                <p>
                    The content on the UniGet website may include technical,
                    typographical, or photographic errors. UniGet does not
                    warrant that any of the materials on its website are
                    accurate, complete, or current. UniGet may make changes to
                    the materials contained on its website at any time without
                    notice. UniGet does not, however, make any commitment to
                    update the materials.
                </p>
            </section>

            <section>
                <h3>7. Modifications to Terms of Service</h3>
                <p>
                    UniGet may revise these Terms of Service at any time without
                    notice. By using this website, you agree to be bound by the
                    then-current version of these Terms of Service.
                </p>
            </section>

            <section>
                <h3>8. Governing Law</h3>
                <p>
                    Any claim relating to the UniGet website shall be governed
                    by the laws of the country in which UniGet operates, without
                    regard to its conflict of law provisions.
                </p>
            </section>
            <section>
                <h3>9. Third-Party Services and Links</h3>
                <p>
                    UniGet may provide links to third-party websites or services
                    that are not owned or controlled by UniGet. UniGet has no
                    control over, and assumes no responsibility for, the
                    content, privacy policies, or practices of any third-party
                    websites or services. By using the UniGet website, you
                    acknowledge and agree that UniGet shall not be responsible
                    or liable, directly or indirectly, for any damage or loss
                    caused or alleged to be caused by or in connection with the
                    use of or reliance on any such content, goods or services
                    available on or through any such third-party websites or
                    services.
                </p>
            </section>
            <section>
                <h3>10. Indemnification</h3>
                <p>
                    You agree to indemnify, defend, and hold harmless UniGet,
                    its officers, directors, employees, agents, licensors, and
                    suppliers from and against all losses, expenses, damages,
                    and costs, including reasonable attorneys' fees, resulting
                    from any violation of these Terms of Service or any activity
                    related to your account (including negligent or wrongful
                    conduct) by you or any other person accessing the UniGet
                    website using your account.
                </p>
            </section>

            <section>
                <h3>11. Disclaimer of Warranties</h3>
                <p>
                    The UniGet website and its services are provided on an "as
                    is" and "as available" basis. UniGet makes no
                    representations or warranties of any kind, express or
                    implied, as to the operation of the website, the
                    information, content, materials, or products included on the
                    website. To the full extent permissible by applicable law,
                    UniGet disclaims all warranties, express or implied,
                    including, but not limited to, implied warranties of
                    merchantability and fitness for a particular purpose. UniGet
                    does not warrant that the website, its servers, or email
                    sent from UniGet are free of viruses or other harmful
                    components. UniGet will not be liable for any damages of any
                    kind arising from the use of the website, including, but not
                    limited to, direct, indirect, incidental, punitive, and
                    consequential damages.
                </p>
            </section>

            <section>
                <h3>12. Termination</h3>
                <p>
                    UniGet reserves the right, in its sole discretion, to
                    terminate your access to the website and the related
                    services or any portion thereof at any time, without notice.
                    Upon termination, you must cease all use of the website and
                    destroy any downloaded or printed materials obtained from
                    the website.
                </p>
            </section>

            <section>
                <h3>13. Contact Information</h3>
                <p>
                    If you have any questions or concerns about these Terms of
                    Service, please contact us at [support@uniget.com] or by
                    using the contact form on our website.
                </p>
            </section>

            <footer>
                <p>
                    &copy; {new Date().getFullYear()} UniGet. All rights
                    reserved.
                </p>
            </footer>
        </div>
    );
};

const Privacy = () => {
    const navigate = useNavigate();
    return (
        <div
            style={{
                maxWidth: "668px",
                margin: "0 auto",
                padding: "0 20px",
            }}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                wrap="nowrap"
            >
                <IconButton
                    onClick={() => {
                        goBackOrHome();
                    }}
                    sx={{ margin: "5px" }}
                >
                    <ArrowBackIos />
                </IconButton>
                <Typography
                    variant="body1"
                    sx={{
                        p: 0,
                        m: 0,
                        fontWeight: 700,
                        py: 1,
                        width: "100%",
                    }}
                >
                    Uniget Privacy Policy
                </Typography>
            </Grid>

            <section>
                <h3>1. Introduction</h3>
                <p>
                    At UniGet, your privacy is important to us. This Privacy
                    Policy explains how we collect, use, disclose, and safeguard
                    your information when you visit our website or use our
                    services. Please read this Privacy Policy carefully. If you
                    do not agree with the terms of this policy, please do not
                    access the site or use our services.
                </p>
            </section>

            <section>
                <h3>2. Information We Collect</h3>
                <p>We may collect information about you in various ways:</p>
                <ul>
                    <li>
                        <strong>Personal Information:</strong> We collect
                        personal information that you provide to us when you
                        register for an account, update your profile, or request
                        support. This may include your name, email address, and
                        other identifying information.
                    </li>
                    <li>
                        <strong>Usage Information:</strong> We collect
                        information about your use of our website and services,
                        such as your search queries, the content you view or
                        interact with, and the date and time of your activities.
                    </li>
                    <li>
                        <strong>Device and Log Information:</strong> We collect
                        information about the device you use to access our
                        services, such as your IP address, browser type,
                        operating system, and device identifiers.
                    </li>
                </ul>
            </section>

            <section>
                <h3>3. Use of Information</h3>
                <p>
                    We use the information we collect for various purposes,
                    including:
                </p>
                <ul>
                    <li>To provide, maintain, and improve our services.</li>
                    <li>
                        To personalize your experience on our website and tailor
                        content to your interests.
                    </li>
                    <li>
                        To communicate with you and respond to your inquiries or
                        support requests.
                    </li>
                    <li>
                        To monitor and analyze usage and trends, and improve the
                        functionality and user experience of our services.
                    </li>
                    <li>
                        To detect, prevent, or investigate security breaches,
                        fraud, or other potentially illegal activities.
                    </li>
                </ul>
            </section>

            <section>
                <h3>4. Sharing of Information</h3>
                <p>
                    We may share your information with third parties in the
                    following situations:
                </p>
                <ul>
                    <li>
                        <strong>Service Providers:</strong> We may share your
                        information with third-party service providers that
                        perform services on our behalf, such as hosting,
                        analytics, and customer support.
                    </li>
                    <li>
                        <strong>Legal Requirements:</strong> We may disclose
                        your information if required to do so by law or in
                        response to a legal request, such as a subpoena or court
                        order.
                    </li>
                    <li>
                        <strong>Business Transfers:</strong> We may share or
                        transfer your information in connection with a merger,
                        acquisition, or sale of assets, or in the event of
                        bankruptcy.
                    </li>
                </ul>
            </section>

            <section>
                <h3>5. Security</h3>
                <p>
                    We take reasonable measures to protect your information from
                    unauthorized access, disclosure, or destruction. However, no
                    method of electronic transmission or storage is completely
                    secure, and we cannot guarantee the absolute security of
                    your information.
                </p>
            </section>

            <section>
                <h3>6 Cookies and Tracking Technologies</h3>
                <p>
                    We may use cookies, web beacons, and other tracking
                    technologies to collect information about your use of our
                    website and services. Cookies are small text files stored on
                    your device that help us to remember your preferences,
                    analyze website traffic, and improve your experience. You
                    may choose to disable cookies through your browser settings;
                    however, doing so may limit your ability to use certain
                    features of our website and services.
                </p>
            </section>
            <section>
                <h3>7. Third-Party Websites</h3>
                <p>
                    Our website may contain links to third-party websites that
                    are not controlled or operated by UniGet. We encourage you
                    to review the privacy policies of these websites, as we have
                    no control over their content or privacy practices.
                </p>
            </section>
            <section>
                <h3>8. Children's Privacy</h3>
                <p>
                    Our services are not intended for use by children under the
                    age of 13, and we do not knowingly collect personal
                    information from children under 13. If we become aware that
                    we have collected personal information from a child under
                    the age of 13, we will take steps to remove that information
                    and terminate the child's account.
                </p>
            </section>
            <section>
                <h3>9. Changes to This Privacy Policy</h3>
                <p>
                    We may update this Privacy Policy from time to time to
                    reflect changes in our practices or for other operational,
                    legal, or regulatory reasons. We will notify you of any
                    material changes by posting the updated policy on our
                    website, and we encourage you to review this policy
                    periodically to stay informed about our privacy practices.
                </p>
            </section>
            <section>
                <h3>10. Contact Us</h3>
                <p>
                    If you have any questions or concerns about this Privacy
                    Policy, please contact us at support@uniget.com or by using
                    the contact form on our website.
                </p>
            </section>
            <footer>
                <p>
                    &copy; {new Date().getFullYear} UniGet. All rights reserved.
                </p>
            </footer>
        </div>
    );
};

const Contact = () => {
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: "",
        },
        onSubmit: async (values) => {
            try {
                const response = await axios.post(
                    "https://formspree.io/f/fainir2006@gmail.com",
                    {
                        name: values.name,
                        email: values.email,
                        message: values.message,
                    }
                );

                if (response.status === 200) {
                    alert("Message sent successfully!");
                    formik.resetForm();
                } else {
                    alert("An error occurred. Please try again later.");
                }
            } catch (error) {
                alert("An error occurred. Please try again later.");
            }
        },
    });
    return (
        <div
            style={{
                maxWidth: "668px",
                margin: "0 auto",
                padding: "0 20px",
            }}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                wrap="nowrap"
            >
                <IconButton
                    onClick={() => {
                        goBackOrHome();
                    }}
                    sx={{ margin: "5px" }}
                >
                    <ArrowBackIos />
                </IconButton>
                <Typography
                    variant="body1"
                    sx={{
                        p: 0,
                        m: 0,
                        fontWeight: 700,
                        py: 1,
                        width: "100%",
                    }}
                >
                    Contact Us / Feedback
                </Typography>
            </Grid>

            <form
                onSubmit={formik.handleSubmit}
                style={{ position: "relative", top: 0 }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    wrap="nowrap"
                >
                    <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label="Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        required
                        sx={{ mt: 4 }}
                    />

                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        required
                        sx={{ my: 2 }}
                    />

                    <TextField
                        fullWidth
                        id="message"
                        name="message"
                        label="Message"
                        multiline
                        rows={5}
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        required
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Send
                    </Button>
                </Grid>
            </form>
        </div>
    );
};

const BotBuilding = () => {
    return (
        <div>
            <TopBar />
            <Container
                maxWidth="668px"
                sx={{
                    maxWidth: "668px",
                }}
            >
                <Box my={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Getting Started with Uniget: Building and Deploying
                        Chatbots
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Building and deploying a chatbot to Uniget involves
                        three key steps:
                    </Typography>
                    <ol>
                        <li>1. Develop Your Chatbot</li>
                        <li>2. Define Your Chatbot Manifest</li>
                        <li>3. Deploy Your Chatbot to Uniget</li>
                    </ol>
                    <Divider variant="middle" />
                    <Typography variant="h5" component="h2" gutterBottom>
                        1. Develop Your Chatbot
                    </Typography>
                    Your chatbot can be designed using any framework, as long as
                    it provides an API that can be accessed via HTTP. The API
                    should expose all the functionalities of your chatbot that
                    you want to make available to users on the Uniget platform.
                    For instance, let's consider you're building a chatbot for
                    providing weather updates. The endpoints of your API could
                    include operations like retrieving current weather, fetching
                    forecast for the next few days, etc.
                    <Divider variant="middle" />
                    <Typography variant="h5" component="h2" gutterBottom>
                        2. Define Your Chatbot Manifest
                    </Typography>
                    Once your chatbot is ready, the next step is to define a
                    manifest file in JSON format. This manifest file, named
                    bot-manifest.json, provides important information about your
                    chatbot to Uniget. Here's an example of what your manifest
                    file could look like:
                    <pre>
                        <code>
                            {`{
    "manifest_version": "v1",
    "chatbot_name": "WeatherBot",
    "chatbot_description": "Your one-stop solution for weather updates and forecasts",
    "auth": {
        "type": "none"
    },
    "api": {
        "type": "openapi",
        "url": "http://localhost:8080/openapi.yaml",
        "is_user_authenticated": false
    },
    "logo_url": "http://localhost:8080/logo.png",
    "contact_email": "support@weatherbot.com",
    "legal_info_url": "http://www.weatherbot.com/legal"
}`}
                        </code>
                    </pre>
                    This manifest file contains essential metadata about your
                    chatbot like its name, description, and API details. Uniget
                    uses this data to facilitate seamless interaction between
                    users and your chatbot.
                    <Divider variant="middle" />
                    <Typography variant="h5" component="h2" gutterBottom>
                        3. Deploy Your Chatbot to Uniget
                    </Typography>
                    After creating the manifest file, the final step is to
                    deploy your chatbot to the Uniget platform. The manifest
                    file needs to be hosted on the same domain as your API. For
                    example, if your API is hosted at http://localhost:8080, you
                    should make the manifest file accessible at
                    http://localhost:8080/.well-known/bot-manifest.json. When
                    deploying your chatbot, make sure to use HTTPS for remote
                    servers. For local development and testing, you can use
                    HTTP. Once deployed, your chatbot will be discoverable on
                    the Uniget platform and users can start leveraging its
                    functionalities.
                    <Divider variant="middle" />
                    <Typography variant="h5" component="h2" gutterBottom>
                        Example Projects
                    </Typography>
                    Here are a few examples of chatbots that can be developed
                    and deployed to Uniget: WeatherBot: A chatbot that provides
                    current weather updates and forecasts for different
                    locations. NewsBot: A chatbot that fetches the latest news
                    from various categories like technology, sports, politics,
                    etc. FitnessBot: A chatbot that provides fitness advice,
                    workout routines, and tracks user's fitness progress.
                    BookFinderBot: A chatbot that recommends books based on
                    user's preferences and provides information about different
                    books.
                </Box>
            </Container>
        </div>
    );
};
